import actionTypes from "./orderDetails.actionTypes";
import initialState from "./orderDetails.initialState";

const orderDetailsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.ORDER_DETAILS_LOADING_START:
			return {
				...state,
				isOrderDetailsLoading: true,
				orderDetailsResponse: null,
				orderDetailsError: null,
			};

		case actionTypes.ORDER_DETAILS_LOADING_SUCCESS:
			return {
				...state,
				isOrderDetailsLoading: false,
				orderDetailsResponse: payload,
			};

		case actionTypes.ORDER_DETAILS_LOADING_ERROR:
			return {
				...state,
				isOrderDetailsLoading: false,
				orderDetailsError: payload,
			};

		default:
			return state;
	}
};

export default orderDetailsReducer;