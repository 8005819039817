import actionTypes from "./flyer.actionTypes";

const flyerLoadStart = () => ({
	type: actionTypes.FLYER_LOAD_START,
});

const flyerLoadSuccess = (shop) => ({
	type: actionTypes.FLYER_LOAD_SUCCESS,
	payload: shop,
});

const flyerLoadError = (errorMessage) => ({
	type: actionTypes.FLYER_LOAD_ERROR,
	payload: errorMessage,
});

export default {
	flyerLoadStart,
	flyerLoadSuccess,
	flyerLoadError,
};