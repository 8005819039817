import actionTypes from "./orderDetails.actionTypes";

const orderDetailsLoadingStart = () => ({
	type: actionTypes.ORDER_DETAILS_LOADING_START,
});

const orderDetailsLoadingSuccess = (orderDetailsResponse) => ({
	type: actionTypes.ORDER_DETAILS_LOADING_SUCCESS,
	payload: orderDetailsResponse,
});

const orderDetailsLoadingError = (errorMessage) => ({
	type: actionTypes.ORDER_DETAILS_LOADING_ERROR,
	payload: errorMessage
});

export default {
	orderDetailsLoadingStart,
	orderDetailsLoadingSuccess,
	orderDetailsLoadingError,
};