import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from 'universal-cookie';

const firebaseConfig = {
    apiKey: "AIzaSyC5YyUAnKjvZUQ5cGW93LnBeF2PaQoQWdo",
    authDomain: "singlecart.firebaseapp.com",
    databaseURL: "https://singlecart.firebaseio.com",
    projectId: "singlecart",
    storageBucket: "singlecart.appspot.com",
    messagingSenderId: "43057026924",
    appId: "1:43057026924:web:d99fca8c9e3689fe7f4549",
    measurementId: "G-V44N9TLVTB"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const cookies = new Cookies();

export const fetchToken = (setTokenFound) => {

    //  console.log('Fetching token');
    return getToken(messaging, { vapidKey: 'BGLYm3bxHVZ6lqvJhAqrHSMK_G8g5_fvmNIu8bMe6wG4PiWSYT5YK03s7uMFWoL90VHvVXZVT1zoo_6F5fNKCoQ' }).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            setTokenFound(true);

            cookies.set('sc_token', currentToken, { path: '/' });

            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });