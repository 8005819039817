import apiClient from "../helpers/apiClient";

class CartUpdateService {

	updateCartWishList = (userID = 0, authKey = "", session = "test", browserAgent = "empty", shopID = 0, stockID = 0, qty , inputType = 1, isAdding = true) => apiClient().post('/CartWishUpdate',
		{
			UserID: userID,
			AuthKey: authKey,
			Session: session,
			BrowserAgent: navigator.userAgent,
			ShopID: shopID,
			IpAddress: "1",
			StockID: stockID,
			Quantity: qty,
			InputType: inputType,
			IsAdd: isAdding
		}
	);
}

export default new CartUpdateService();