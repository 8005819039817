import actionTypes from "./sendCode.actionTypes";
import initialState from "./sendCode.initialState";

const sendCodeReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SEND_CODE_LOAD_START:
			return {
				...state,
				isSendCodeLoading: true,
				sendCodeData: null,
				errorMessage: null,
			};

		case actionTypes.SEND_CODE_SUCCESS:
			return {
				...state,
				isSendCodeLoading: false,
				sendCodeData: payload,
			};

		case actionTypes.SEND_CODE_ERROR:
			return {
				...state,
				isSendCodeLoading: false,
				errorMessage: payload,
			};

		default:
			return state;
	}
};

export default sendCodeReducer;