import CartUpdateService from "../../../services/checkOut.service";
import actions from "./checkout.actions";
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";

export const checkOutAsync = (shopID = 0, addressID = 0) => (dispatch) => {

	dispatch(actions.checkOutLoadStart());
	//const { shop } = useSelector((state) => state.shopReducer);
	const cookies = new Cookies();

	CartUpdateService.getCheckOutData(
		cookies.get('sc_id'),
		cookies.get('sd_auth'),
		cookies.get('_scid'),
		navigator.userAgent,
		cookies.get('_shopID'),
		addressID,
		cookies.get('_lat'),
		cookies.get('_long'))
		.then((response) => {
			// console.log(response);
			dispatch(actions.checkOutLoadSuccess(response.data));
		})
		.catch((error) => dispatch(actions.checkOutLoadError(error.message)));
};