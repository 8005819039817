
import ListAddress from "../../../../services/address/listAddress.service";
import actions from "./listAddressReducer.actions";
import Cookies from 'universal-cookie';

export const loadListAddress = () => (dispatch) => {

	const cookies = new Cookies();
	dispatch(actions.listAddressLoadStart());

	ListAddress.getCustomerAddress(
		cookies.get('sc_id'),
		cookies.get('sd_auth'))
		.then((response) => dispatch(actions.listAddressLoadSuccess(response.data)))
		.catch((error) => dispatch(actions.listAddressLoadError(error.message)));
};