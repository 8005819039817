import React from 'react';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { Image } from 'react-bootstrap';

class CartDropdownItem extends React.Component {
  render() {
    return (
      <>

        <Image className={"mr-3 rounded-pill "} src={this.props.imageUrl} alt={this.props.title} style={{ width: '10%', height: '' }} />
        {this.props.title}
        <span className="float-right text-secondary" style={{ float: 'right' }}> {this.props.price}</span>


        <div className="mb-2">
          Quantity  : {this.props.quantity} Nos.
          <strong> <span className="float-right text-secondary" style={{ float: 'right' }}>Total : {this.props.totalPrice}</span>
          </strong>
        </div>
        <hr />

      </>

    );
  }
}

CartDropdownItem.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  totalPrice: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclass: PropTypes.string.isRequired
};

CartDropdownItem.defaultProps = {
  title: '',
  price: '',
  icoIcon: '',
  iconclass: '',
}

export default CartDropdownItem;