import actionTypes from "./placeOrder.actionTypes";
import initialState from "./placeOrder.initialState";

const placeOrderReducer = (state = initialState, { type, payload }) => {
	switch (type) {

		case actionTypes.PLACE_ORDER_LOAD_START:
			return {
				...state,
				isPlaceOrderLoading: true,
				placeOrderData: null,
				placeOrderError: null,
			};

		case actionTypes.PLACE_ORDER_SUCCESS:
			return {
				...state,
				isPlaceOrderLoading: false,
				placeOrderData: payload,
			};

		case actionTypes.PLACE_ORDER_ERROR:
			return {
				...state,
				isPlaceOrderLoading: false,
				placeOrderError: payload,
			};

		default:
			return state;
	}
};

export default placeOrderReducer;