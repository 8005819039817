import actionTypes from "./checkout.actionTypes";

const checkOutLoadStart = () => ({
	type: actionTypes.CHECK_OUT_LOAD_START,
});

const checkOutLoadSuccess = (checkOutData) => ({
	type: actionTypes.CHECK_OUT_SUCCESS,
	payload: checkOutData,
});

const checkOutLoadError = (errorMessage) => ({
	type: actionTypes.CHECK_OUT_ERROR,
	payload: errorMessage,
});

export default {
	checkOutLoadStart,
	checkOutLoadSuccess,
	checkOutLoadError,
};