import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Form,
  InputGroup,
  FormControl,
  Button,
  Image,
} from "react-bootstrap";
import FontAwesome from "./FontAwesome";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Paper from "@mui/material/Paper";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ArticleIcon from "@mui/icons-material/Article";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { checkOutAsync } from "../../redux/reducers/checkout/checkout.thunks";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import CallIcon from "@mui/icons-material/Call";
import DirectionsIcon from "@mui/icons-material/Directions";
import ShareIcon from "@mui/icons-material/Share";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import LogoutIcon from "@mui/icons-material/Logout";
import ListSubheader from "@mui/material/ListSubheader";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Cookies from "universal-cookie";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useParams } from "react-router-dom";
import MapIcon from "@mui/icons-material/Map";

import { QRCodeSVG } from "qrcode.react";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Footer = (props) => {
  const [value, setValue] = React.useState("recents");
  const { isLoading, shop, errorMessage } = useSelector(
    (state) => state.shopReducer
  );
  const { isCheckoutLoading, checkOutData, checkOutErrorMessage } = useSelector(
    (state) => state.checkOutReducer
  );

  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const { isLoginLoading, loginData, loginErrorMessage } = useSelector(
    (state) => state.loginReducer
  );

  const cookies = new Cookies();
  const dispatch = useDispatch();

  const { shopCode } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (shop) {
      dispatch(checkOutAsync(0));
    }
  }, [shop]);

  useEffect(() => {
    if (loginData != null) {
      if (loginData.Error == false) {
        cookies.set("sc_logged_in", "1", { path: "/" });
        cookies.set("sc_id", loginData.UserID, { path: "/" });
        cookies.set("sd_auth", loginData.SessionKey, { path: "/" });
        cookies.set("sc_name", loginData.CustomerName, { path: "/" });
        setLoggedIn(true);
      }
    }

    if (cookies.get("sc_logged_in") == "1") {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [loginData]);

  const [drawerStatus, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(drawerStatus, false)}
      onKeyDown={toggleDrawer(drawerStatus, false)}
    >
      <List>
        {isLoggedIn == true ? (
          <>
            <ListSubheader component="div" id="nested-list-subheader">
              My Account
            </ListSubheader>
            <ListItem
              button
              key="address"
              component={Link}
              to={"/" + shopCode + "/my-address"}
            >
              <ListItemIcon>
                <HomeIcon sx={{ color: "#E65F14" }} />
              </ListItemIcon>
              <ListItemText primary="Address" />
            </ListItem>

            <ListItem
              button
              key="orderHistory"
              component={Link}
              to={"/" + shopCode + "/my-order-history"}
            >
              <ListItemIcon>
                <HistoryIcon sx={{ color: "#E65F14" }} />
              </ListItemIcon>
              <ListItemText primary="Order History" />
            </ListItem>
          </>
        ) : (
          <></>
        )}
      </List>
      <Divider />
      <ListSubheader component="div" id="nested-list-subheader">
        Contact Us
      </ListSubheader>

      <ListItem
        button
        key="Call"
        onClick={() => {
          shop && window.open("tel:" + shop.ShopDetails.PrimaryMobile);
        }}
      >
        <ListItemIcon>
          <CallIcon sx={{ color: "#08A5ED" }} />
        </ListItemIcon>
        <ListItemText primary="Call" />
      </ListItem>

      <ListItem
        button
        key="WhatsApp"
        onClick={() => {
          shop &&
            window.open(
              "https://api.whatsapp.com/send?phone=" +
                shop.ShopDetails.PrimaryMobile +
                "&text=Hi%20There!"
            );
        }}
      >
        <ListItemIcon>
          <WhatsAppIcon sx={{ color: "#128c7e" }} />
        </ListItemIcon>
        <ListItemText primary="WhatsApp" />
      </ListItem>

      <ListItem
        button
        key="Directions"
        onClick={() => {
          shop &&
            window.open(
              "https://maps.google.com/?q=" +
                shop.ShopDetails.Latitude +
                "," +
                shop.ShopDetails.Longitude +
                ""
            );
        }}
      >
        <ListItemIcon>
          <DirectionsIcon sx={{ color: "blue" }} />
        </ListItemIcon>
        <ListItemText primary="Map Location" />
      </ListItem>

      <ListItem
        button
        key="Directions"
        onClick={() => {
          shop &&
            window.open(
              "https://maps.google.com/?q=" +
                shop.ShopDetails.Latitude +
                "," +
                shop.ShopDetails.Longitude +
                ""
            );
        }}
      >
        <ListItemIcon>
          <MapIcon sx={{ color: "red" }} />
        </ListItemIcon>
        <ListItemText primary={shop && shop.ShopDetails.Address} />
      </ListItem>

      <Typography
        variant="subtitle3"
        gutterBottom
        component="div"
        style={{ padding: 10 }}
      ></Typography>

      <Divider />

      {isLoggedIn ? (
        <>
          <ListItem button key="Sign Out">
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </>
      ) : (
        <></>
      )}

      {shop && (
        <>
          <div style={{ margin: "10px", textAlign: "center" }}>
            <QRCodeSVG value={"https://shop.singlecart.io/" + shopCode} />
          </div>
        </>
      )}
    </Box>
  );

  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
        z-index={9999}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<HomeIcon />}
            component={Link}
            to={"/" + shopCode + ""}
          />
          <BottomNavigationAction
            label="Offer"
            icon={<LocalOfferIcon />}
            component={Link}
            to={"/" + shopCode + "/offers"}
          />
          <BottomNavigationAction
            label="Flyer"
            icon={
              <ArticleIcon />
              // <StyledBadge badgeContent={'New'} color="secondary">
              // 	<ArticleIcon />
              // </StyledBadge>
            }
            component={Link}
            to={"/" + shopCode + "/flyers"}
          />
          <BottomNavigationAction
            label="My cart"
            icon={
              <StyledBadge
                badgeContent={
                  checkOutData ? checkOutData.ItemList.length.toString() : ""
                }
                color="secondary"
              >
                <ShoppingCartIcon />
              </StyledBadge>
            }
            component={Link}
            to={"/" + shopCode + "/checkout"}
          />
          <BottomNavigationAction
            label="Contact"
            icon={<MoreVertIcon />}
            onClick={toggleDrawer(true)}
          />
        </BottomNavigation>
      </Paper>

      <Drawer anchor="right" open={drawerStatus} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>

      <footer className="pt-4 pb-4 text-center">
        <Container>
          {/* <p className="mt-0 mb-0">{this.props.copyrightText}</p>
						<small className="mt-0 mb-0"> Made with <FontAwesome icon={this.props.madewithIconclassName} /> by
							<Link className="text-danger ml-1" target="_blank" to={`${this.props.firstLink}`}>{this.props.firstLinkText}</Link> - <Link className="text-primary" target="_blank" to={this.props.secondLink}>{this.props.secondLinkText}</Link>
						</small> */}
        </Container>
      </footer>
    </>
  );
};

Footer.propTypes = {
  sectionclassName: PropTypes.string,
  popularCHclassName: PropTypes.string,
  popularCountries: PropTypes.array,
  popularFHclassName: PropTypes.string,
  popularFood: PropTypes.array,
  copyrightText: PropTypes.string,
  madewithIconclassName: PropTypes.string,
  firstLinkText: PropTypes.string,
  firstLink: PropTypes.string,
  secondLinkText: PropTypes.string,
  secondLink: PropTypes.string,
};

Footer.defaultProps = {
  sectionclassName: "footer-bottom-search pt-5 pb-5 bg-white",
  popularCHclassName: "text-black",
  popularCountries: [],
  popularFHclassName: "mt-4 text-black",
  popularFood: [],
  copyrightText: "© Copyright 2020 Osahan Eat. All Rights Reserved",
  madewithIconclassName: "heart heart-icon text-danger",
  firstLinkText: "Gurdeep Osahan",
  firstLink: "//www.instagram.com/iamgurdeeposahan/",
  secondLinkText: "Askbootstrap",
  secondLink: "//askbootstrap.com/",
};

export default Footer;
