import FlyerService from "../../../services/flyer.service";
import actions from "./flyer.actions";

export const loadFlyerAsync = (shopID, latitude, longitude) => (dispatch) => {

	dispatch(actions.flyerLoadStart());

	FlyerService.getFlyerList(shopID, latitude, longitude)
		.then((response) => dispatch(actions.flyerLoadSuccess(response.data)))
		.catch((error) => dispatch(actions.flyerLoadError(error.message)));
};