import apiClient from "../helpers/apiClient";

class ShopService {
	getShopDetails = (shopCode, latitude = "", longitude = "") => apiClient().post('/SingleCartShop', {
		webShopName: shopCode,
		Latitude: latitude,
		Longitude: longitude
	});
}

export default new ShopService();