import ProductService from "../../../services/products.service";
import actions from "./loadSingleProducts.actions";
import Cookies from 'universal-cookie';

export const loadSingleProduct = (stockID) => (dispatch) => {

	dispatch(actions.singleProductsLoadStart());
	const cookies = new Cookies();

	ProductService.getSingleProduct(
		stockID,
		cookies.get('_lat'),
		cookies.get('_long'))
		.then((response) => dispatch(actions.singleProductsLoadSuccess(response.data)))
		.catch((error) => dispatch(actions.singleProductsLoadError(error.message)));
};