import ShopService from "../../../services/shop.service";
import actions from "./shop.actions";
import Cookies from 'universal-cookie';

export const loadShopAsync = (shopCode, latitude, longitude) => (dispatch) => {

	dispatch(actions.shopLoadStart());
	const cookies = new Cookies();

	ShopService.getShopDetails(shopCode, cookies.get('_lat'),
		cookies.get('_long'))
		.then((response) => dispatch(actions.shopLoadSuccess(response.data)))
		.catch((error) => dispatch(actions.shopLoadError(error.message)));
};