import actionTypes from "./flyer.actionTypes";
import initialState from "./flyer.initialState";

const flyerReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.FLYER_LOAD_START:
			return {
				...state,
				isFlyerLoading: true,
				flyer: null,
				errorMessage: null,
			};

		case actionTypes.FLYER_LOAD_SUCCESS:
			return {
				...state,
				isFlyerLoading: false,
				flyer: payload,
			};

		case actionTypes.FLYER_LOAD_ERROR:
			return {
				...state,
				isFlyerLoading: false,
				errorMessage: payload,
			};

		default:
			return state;
	}
};

export default flyerReducer;
