import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Container } from 'react-bootstrap';
import AddAddressModal from './modals/AddAddressModal';
import DeleteAddressModal from './modals/DeleteAddressModal';
import AddressCard from './common/AddressCard';
import OrderCard from '../components/common/OrderCard';

import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { loadOrderSummaryList } from "../redux/reducers/report/orderSummary/orderSummary.thunks";

const MyOrderHistory = (props) => {

	const [showDeleteModal, showDeleteModalValue] = React.useState(false);
	const [showAddressModal, hideAddressModalValue] = React.useState(false);

	const dispatch = useDispatch();
	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
	const { isOrderSummaryLoading, orderSummaryResponse, orderSummaryError } = useSelector((state) => state.orderSummaryReducer);

	const hideAddressModal = (event, newValue) => {
		showDeleteModalValue(newValue);
	};

	const hideDeleteModal = (event, newValue) => {
		hideAddressModalValue(newValue);
	};

	useEffect(() => {

		dispatch(loadOrderSummaryList());

	}, [shop])

	return (
		<>
			<section style={{ marginTop: 150 }}>
			</section>
			<section className="section pb-5 bg-white">
				<div className='p-4 bg-white shadow-sm'>
					<h4 className="font-weight-bold mt-0 mb-4">Past Orders</h4>

					<Row className="d-flex ">
						{isOrderSummaryLoading ? <ShimmerSimpleGallery card imageHeight={60} caption /> :
							<>
								{orderSummaryResponse && orderSummaryResponse.orderSummariesData.map(orderRow => (
									<Col md={3} >
										<OrderCard
											image='/img/3.jpg'
											imageAlt=''
											orderNumber={orderRow.OrderNo}
											orderDate={orderRow.OrderDate}
											deliveredDate='Mon, Nov 12, 7:18 PM'
											orderTitle={orderRow.OrderNo}
											orderStatus={orderRow.Status}
											address={orderRow.customerAddressResponseData.Address1}
											numberOfItems={orderRow.NoOfItems}
											orderTotal={orderRow.GrossAmount}
											helpLink='#'
											detailLink={'/PromptTech/order-details?orderId=' + orderRow.OrderID}
										/>
									</Col>
								))}
							</>
						}
					</Row>

				</div>
			</section>
		</>
	);
}
export default MyOrderHistory;