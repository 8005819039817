import ProductService from "../../../services/products.service";
import actions from "./products.actions";

export const loadProductsAsync = (shopID, categoryID = 0, searchQuery = "", showNoOfferItems = true, latitude = "", longitude = "") => (dispatch) => {

	dispatch(actions.productsLoadStart());

	ProductService.getProductList(shopID, categoryID, searchQuery, showNoOfferItems, latitude, longitude)
		.then((response) => dispatch(actions.productsLoadSuccess(response.data)))
		.catch((error) => dispatch(actions.productsLoadError(error.message)));
};