import actionTypes from "./sendCode.actionTypes";

const sendCodeLoadingStart = () => ({
	type: actionTypes.SEND_CODE_LOAD_START,
});

const sendCodeSuccess = (sendCodeData) => ({
	type: actionTypes.SEND_CODE_SUCCESS,
	payload: sendCodeData,
});

const sendCodeError = (errorMessage) => ({
	type: actionTypes.SEND_CODE_ERROR,
	payload: errorMessage,
});

export default {
	sendCodeLoadingStart,
	sendCodeSuccess,
	sendCodeError,
};