import SendVerificationCodeService from "../../../../services/auth/sendVerificationCode.service";
import actions from "./sendCode.actions";

export const sendCodeAsync = (mobileNumber, email) => (dispatch) => {

	dispatch(actions.sendCodeLoadingStart());

	SendVerificationCodeService.sendVerificationCode(mobileNumber, email)
		.then((response) => dispatch(actions.sendCodeSuccess(response.data)))
		.catch((error) => dispatch(actions.sendCodeError(error.message)));
};