import actionTypes from "./loadSingleProducts.actionTypes";
import initialState from "./loadSingleProducts.initialState";

const singleProductReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SINGLE_PRODUCT_LOAD_START:
			return {
				...state,
				isSingleProductLoading: true,
				singleProduct: null,
				singleProductPricing: null,
			};

		case actionTypes.SINGLE_PRODUCT_LOAD_SUCCESS:
			return {
				...state,
				isSingleProductLoading: false,
				singleProduct: payload,
			};

		case actionTypes.SINGLE_PRODUCT_LOAD_ERROR:
			return {
				...state,
				isSingleProductLoading: false,
				singleProductPricing: payload,
			};

		default:
			return state;
	}
};

export default singleProductReducer;