import actionTypes from "./checkout.actionTypes";
import initialState from "./checkout.initialState";

const checkOutReducer = (state = initialState, { type, payload }) => {
	switch (type) {

		case actionTypes.CHECK_OUT_LOAD_START:
			return {
				...state,
				isCheckOutLoading: true,
				checkOutData: null,
				checkOutErrorMessage: null,
			};

		case actionTypes.CHECK_OUT_SUCCESS:
			return {
				...state,
				isCheckOutLoading: false,
				checkOutData: payload,
			};

		case actionTypes.CHECK_OUT_ERROR:
			return {
				...state,
				isCheckOutLoading: false,
				checkOutErrorMessage: payload,
			};

		default:
			return state;
	}
};

export default checkOutReducer;