import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Container, InputGroup, Button, Tab, Nav, ButtonToolbar, ToggleButton, ToggleButtonGroup, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import CheckoutItem from './common/CheckoutItem';
import Icofont from 'react-icofont';
import QuickBite from './common/QuickBite';
import AddAddressModal from './modals/AddAddressModal';

import { useDispatch, useSelector } from "react-redux";
import { checkOutAsync } from "../redux/reducers/checkout/checkout.thunks";
import { cartUpdateAsync } from "../redux/reducers/cartUpdate/cartUpdate.thunks";
import { placeOrderAsync } from "../redux/reducers/placeOrder/placeOrder.thunks";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputAdornment from '@mui/material/InputAdornment';
import MapPicker from 'react-google-map-picker';
import { useParams } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Cookies from 'universal-cookie';
import placeOrderReducer from "../redux/reducers/placeOrder/placeOrder.reducer";
import { format } from 'date-fns';



const DefaultLocation = { lat: 10, lng: 106 };
const DefaultZoom = 10;

const AddEditAddress = (props) => {

	const dispatch = useDispatch();
	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
	const { isCheckoutLoading, checkOutData, checkOutErrorMessage } = useSelector((state) => state.checkOutReducer);
	const { isPlaceOrderLoading, placeOrderData, placeOrderError } = useSelector((state) => state.placeOrderReducer);


	const [value, setValue] = React.useState(new Date());
	const [showAddressModal, setShowAddressModel] = React.useState(true);

	// const handleOpenAddressModel = () => {
	// 	setShowAddressModel(false);
	// };

	const handleCloseAddressModel = () => {
		setShowAddressModel(false);
	};

	const { shopCode } = useParams();
	// console.log(shopCode);

	const [open, setOpen] = React.useState(false);
	const [openSnackBar, setOpenSnackBar] = React.useState(false);

	const [addressID, setAddressID] = useState(0);
	const [isSuggestedDelivery, setIsSuggestedDelivery] = useState(false);
	const [strSuggestedDate, setStrSuggestedDate] = useState('');
	const [strRemark, setStrRemark] = useState('');
	const [blnIsShopVisit, setBlnIsShopVisit] = useState(false);
	const [blnIsGuest, setBlnIsGuest] = useState(false);
	const [strName, setStrName] = useState('');
	const [strEmail, setStrEmail] = useState('');
	const [strAddress, setStrAddress] = useState('');
	const [strPhone, setStrPhone] = useState('');
	const [strTableName, setTableName] = useState('');

	const cookies = new Cookies();
	const [isLoggedIn, setLoggedIn] = React.useState(false);

	useEffect(() => {
		if (cookies.get('sc_logged_in') == "1") {
			setLoggedIn(true);
		} else {
			setLoggedIn(false);
		}
	}, [])

	const [snackBarMessage, setSnackBarMessage] = React.useState("");
	const showSnackBarMessage = (message) => {
		setOpenSnackBar(true);
		setSnackBarMessage(message);
	};

	useEffect(() => {
		if (placeOrderData != null) {
			if (placeOrderData.Error == false) {
				showSnackBarMessage(placeOrderData.Message)
				setOpen(false);
				// placeOrderReducer()
				// handleCloseAllModal();
			} else {
				showSnackBarMessage(placeOrderData.Message)
			}
		}
	}, [placeOrderData])

	const updateAddressID = (value) => {
		setAddressID(value);
	}

	const updateIsSuggestedDelivery = (value) => {
		setIsSuggestedDelivery(value);
	}

	const updateStrSuggestedDate = (value) => {

		// console.log(format(value, 'yyyy-MM-dd hh:mm:ss'))

		setStrSuggestedDate(format(value, 'yyyy-MM-dd hh:mm:ss'));
	}

	const updateStrRemark = (value) => {
		setStrRemark(value);
	}

	const updateBlnIsShopVisit = (value) => {
		setBlnIsShopVisit(value);
	}
	const updateBlnIsGuest = (value) => {
		setBlnIsGuest(value);
	}
	const updateStrName = (value) => {
		setStrName(value);
	}

	const updateStrPhone = (value) => {
		setStrPhone(value);
	}

	const updateStrEmail = (value) => {
		setStrEmail(value);
	}
	const updateStrAddress = (value) => {
		setStrAddress(value);
	}

	const updateTableName = (value) => {
		setTableName(value);
	}

	const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
	const [location, setLocation] = useState(defaultLocation);
	const [zoom, setZoom] = useState(DefaultZoom);

	function handleChangeLocation(lat, lng) {
		setLocation({ lat: lat, lng: lng });
	}

	function handleChangeZoom(newZoom) {
		setZoom(newZoom);
	}

	function handleResetLocation() {
		setDefaultLocation({ ...DefaultLocation });
		setZoom(DefaultZoom);
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpenSnackBar(false);
	};

	const handleCloseSnackBar = () => {
		setOpenSnackBar(false);
	};

	const [showScheduleOrder, setShowScheduleOrder] = React.useState(false);


	useEffect(() => {
		if (shop) {
			dispatch(checkOutAsync(0));
		}
	}, [shop])

	function hideAddressModal() {
		showAddressModal = false;
	}

	function updateCart(stockID, quantity) {
		dispatch(cartUpdateAsync(stockID, quantity, 1, true));
	}

	function confirmOrder(event) {
		event.preventDefault();

		if (strName.length < 1) {
			setOpenSnackBar(true);
			setSnackBarMessage("Please enter a valid name");
			return false;
		}

		if (strPhone.length < 9) {
			setOpenSnackBar(true);
			setSnackBarMessage("Please enter a valid phone number");
			return false;
		}


		if (isLoggedIn) {
			setBlnIsGuest(false);
		} else {
			setBlnIsGuest(true);
		}

		setOpen(true);
	}

	function placeOrder() {
		// alert('address id' + addressID);
		// alert('is suggested' + isSuggestedDelivery);
		// alert('suggested date' + strSuggestedDate);
		// alert('remark ' + strRemark);
		// alert('is shop visit' + blnIsShopVisit);
		// alert('is guest ' + blnIsGuest);
		// alert('name ' + strName);
		// alert('phone ' + strPhone);
		// alert('email ' + strEmail);
		// alert('address ' + strAddress);

		var remark = "";

		if (strTableName.trim().length > 0) {
			remark = "Table Number : " + strTableName + " . " + strRemark;
		} else {
			remark = strRemark;
		}

		dispatch(placeOrderAsync(addressID, isSuggestedDelivery,
			strSuggestedDate, remark, blnIsShopVisit, blnIsGuest,
			strName, strPhone, strEmail, strAddress));
	}

	function handleChange(event) {
		var scheduler = showScheduleOrder;
		setShowScheduleOrder(!scheduler)
		updateIsSuggestedDelivery(!scheduler)
	}

	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleCloseSnackBar}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	const handleRadioChange = (event) => {

		event.preventDefault();

		if (event.target.value == 'Yes') {
			setShowScheduleOrder(true)
			updateIsSuggestedDelivery(true)
		} else {
			setShowScheduleOrder(false)
			updateIsSuggestedDelivery(false)
		}
	};

	const [showAddressText, setShowAddressText] = React.useState(false);
	const [showTableText, setShowTableText] = React.useState(false);
	const handleDeliveryTypeRadioChange = (event) => {
		event.preventDefault();

		if (event.target.value == 'Deliver') {
			setShowAddressText(true);
			updateBlnIsShopVisit(false);
			setShowTableText(false);
		} else if (event.target.value == 'DineIn') {
			setShowAddressText(false)
			updateStrAddress("Deliver to table");
			updateBlnIsShopVisit(true);
			setShowTableText(true)
		} else {
			setShowAddressText(false)
			updateStrAddress("Collect from shop");
			updateBlnIsShopVisit(true);
			setShowTableText(false)
		}
	};

	return (
		<>
			<section style={{ marginTop: 130 }}>
			</section>

			<div className="alert alert-success" role="alert">
				Shop is  <strong> {checkOutData ? checkOutData.ShopInfo.Distance.toFixed(2).toString() : '0'}  </strong> Km away<br></br>
				Minimum amount for placing order is <strong>AED {shop && shop.ShopDetails && shop.ShopDetails.MinimumCartValue}</strong>
			</div>

			{/* {
				!isLoggedIn &&
				<div className="alert alert-danger" role="alert">
					Before placing the order, please Login to track order, Add different address etc.
				</div>
			} */}

			{/* <AddAddressModal show={showAddressModal} onHide={handleCloseAddressModel} /> */}

			<section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
				<Container>
					<Form onSubmit={confirmOrder}>
						<Row>

							<Col md={8}>

								<div className="offer-dedicated-body-left col-md-8">
									<div className="bg-white rounded shadow-sm p-4 mb-8">
										<h6 className="mb-3">Please fill your contact details</h6>

										<Box
											component="form"
											sx={{
												'& > :not(style)': { m: 1, },
											}}
											validate
											autoComplete="off"
										>
											<div>
												<TextField
													required
													onChange={(e) => updateStrName(e.target.value)}
													id="strName"
													label="Name"
													variant="outlined"
													fullWidth
												/>
											</div>
											<div>
												<TextField
													required
													onChange={(e) => updateStrPhone(e.target.value)}
													id="strPhone"
													label="Mobile Number"
													type="number"
													InputProps={{
														startAdornment: <InputAdornment position="start">+971 </InputAdornment>,
													}}
													variant="outlined"
													fullWidth
												/>
											</div>
											<div>
												<TextField
													id="strEmail"
													onChange={(e) => updateStrEmail(e.target.value)}
													label="Email"
													type="email"
													variant="outlined"
													fullWidth
												/>

											</div>
										</Box>

										<FormControl style={{ marginTop: '20px' }}>
											<FormLabel id="demo-radio-buttons-group-label">How to deliver this order?</FormLabel>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue="Collect"
												name="radio-buttons-group"
												onChange={handleDeliveryTypeRadioChange}													>

												<FormControlLabel value="Collect" control={<Radio />} label="I will visit shop" />

												{checkOutData && checkOutData.ShopInfo.HomeServiceAllowed ?
													<>
														<FormControlLabel value="Deliver" control={<Radio />} label="Please deliver to my address" />
													</> :
													<>
														[Home delivery is currently not supported in our shop]
													</>
												}

												{shop && shop.ShopDetails.ShopType == 5 ?
													<>
														<FormControlLabel value="DineIn" control={<Radio />} label="Dine In, Deliver to my table" />
													</> :
													<>
													</>
												}



											</RadioGroup>
										</FormControl>

										<Box
											component="form"
											sx={{
												'& > :not(style)': { m: 1, },
											}}
											validate
											autoComplete="off"
										>
											<div>
												{showTableText && <TextField
													onChange={(e) => updateTableName(e.target.value)}
													id="strTable Name"
													label="Table Number*"
													variant="outlined"
													fullWidth
												/>}
											</div>

										</Box>

										<Box
											component="form"
											sx={{
												'& > :not(style)': { m: 1, },
											}}
											validate
											autoComplete="off"
										>
											<div>
												{showAddressText && <TextField
													onChange={(e) => updateStrAddress(e.target.value)}
													id="strAddress"
													label="Address*"
													variant="outlined"
													multiline
													maxRows={4}
													fullWidth
												/>}
											</div>

										</Box>






										<div className="mb-2 bg-white rounded p-2">

										</div>

										{/* 
										<MapPicker defaultLocation={defaultLocation}
											zoom={zoom}
											mapTypeId="roadmap"
											style={{ height: '700px' }}
											onChangeLocation={handleChangeLocation}
											onChangeZoom={handleChangeZoom}
											apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8' /> */}

									</div>
								</div>

							</Col>
							<Col md={4}>
								<div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
									<div className="d-flex mb-4 osahan-cart-item-profile">
										<h6 className="mb-1 text-white">Your bag details
										</h6>
									</div>
									<Col md={12}>
										<div className="bg-white rounded border shadow-sm mb-4">

											{checkOutData && <>
												{checkOutData.ItemList.map(cartRow => {
													return (

														<QuickBite
															{...props}
															updateCart={updateCart}
															id={cartRow.StockID}
															itemClass="menu-list"
															image={cartRow.StockImages[0].ImagePath}
															title={cartRow.ProductName}
															price={cartRow.SellingPrice}
															priceUnit='AED '
															oldPrice={cartRow.ActualPrice}
															discountPercentage={cartRow.Discount}
															qty={cartRow.Quantity}
														/>
													)
												}
												)}
											</>}
										</div>
									</Col>



									<div className="mb-2 bg-white rounded p-2">
										{/* <FormControl>
											<FormLabel id="demo-radio-buttons-group-label">Do you want to schedule this order for later?</FormLabel>
											<FormControlLabel control={<Checkbox
												checked={showScheduleOrder}
												onChange={handleChange} />} label="Yes, I want to schedule this order" />


											{showScheduleOrder ? <>
												<LocalizationProvider dateAdapter={AdapterDateFns}>

													<MobileDateTimePicker style={{ margin: '10px' }}
														value={value}
														onChange={(newValue) => {
															setValue(newValue);
														}}
														renderInput={(params) => <TextField style={{ margin: '10px' }} {...params} />}
													/>
												</LocalizationProvider>
											</> : <>

											</>}


										</FormControl> */}



										<FormControl>
											<FormLabel id="demo-radio-buttons-group-label">Do you want to schedule this order for later?</FormLabel>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue="No"
												name="radio-buttons-group"
												onChange={handleRadioChange}
											>
												<FormControlLabel value="No" control={<Radio />} label="No, I dont want to schedule." />
												<FormControlLabel value="Yes" control={<Radio />} label="Yes, I want to schedule." />
											</RadioGroup>
										</FormControl>

										{showScheduleOrder ? <>
											<LocalizationProvider dateAdapter={AdapterDateFns}>

												<MobileDateTimePicker style={{ margin: '10px' }}
													value={value}
													onChange={(newValue) => {
														updateStrSuggestedDate(newValue);
													}}
													renderInput={(params) => <TextField style={{ margin: '10px' }} {...params} />}
												/>
											</LocalizationProvider>
										</> : <>

										</>}

									</div>

									<div className="mb-2 bg-white rounded p-2 clearfix">
										<InputGroup className="mb-0">
											<InputGroup.Text><Icofont icon="comment" /></InputGroup.Text>
											<Form.Control as="textarea"
												onChange={(e) => updateStrRemark(e.target.value)}
												placeholder="Any suggestions? We will pass it on..." aria-label="With textarea" />
										</InputGroup>
									</div>

									<div className="mb-2 bg-white rounded p-2 clearfix">
										<p className="mb-1">Item Count <span style={{ float: 'right' }} className="float-right text-dark"> {checkOutData ? checkOutData.OrderDetails.ItemCount : '0'} </span></p>
										<p className="mb-1">Gross Amount <span style={{ float: 'right' }} className="float-right text-dark">{checkOutData ? checkOutData.OrderDetails.GrossAmount.toFixed(2).toString() : '0'} AED </span></p>
										<p className="mb-1">Delivery Charge <span style={{ float: 'right' }} className="float-right text-dark">{checkOutData ? checkOutData.OrderDetails.DeliveryCharge.toFixed(2).toString() : '0'} AED </span></p>

										{/* <p className="mb-1">Delivery Fee
										<OverlayTrigger
											key="top"
											placement="top"
											overlay={
												<Tooltip id="tooltip-top">
													Total discount breakup
												</Tooltip>
											}
										>
											<span className="text-info ml-1">
												<Icofont icon="info-circle" />
											</span>
										</OverlayTrigger>
										<span className="float-right text-dark">$10</span>

									</p> */}
										<hr />
										<h6 className="font-weight-bold mb-0">Total Amount <span style={{ float: 'right' }} className="float-right">{checkOutData ? checkOutData.OrderDetails.PayableAmount.toFixed(2).toString() : '0'} AED</span></h6>
									</div>
									<Button to="/thanks" className="btn btn-success btn-block btn-lg" type="submit">Place Order
										<Icofont icon="long-arrow-right" /></Button>
								</div>
								<div className="pt-2"></div>
								<div className="pt-2"></div>
							</Col>

						</Row>
					</Form>
				</Container>
			</section >

			<div className="alert alert-info" role="alert">
				Shop Contact details<br></br>
				Mobile Number <strong> {checkOutData ? checkOutData.ShopInfo.PrimaryMobile : 'Not available'}  </strong> <br></br>
			</div>


			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Do you want place the order?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure want place the order to <strong>{shop && " " + shop.ShopDetails.Name}</strong>	of amount <strong> {checkOutData ? checkOutData.OrderDetails.PayableAmount.toFixed(2).toString() : '0'} AED</strong>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button onClick={placeOrder} autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={openSnackBar}
				autoHideDuration={3000}
				onClose={handleClose}
				message={snackBarMessage}
				action={action}
			/>
		</>
	);
}
export default AddEditAddress;