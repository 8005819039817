import apiClient from "../helpers/apiClient";

class CheckOutService {

	getCheckOutData = (userID = 0, authKey = "", session = "test", browserAgent = "empty", shopID = 0, addressID = 0, latitude = "",longitude = "") => apiClient().post('/CartCheckOutWithSession',
		{
			UserID: userID,
			AuthKey: authKey,
			Session: session,
			BrowserAgent: browserAgent,
			ShopID: shopID,
			AddressID: addressID,
			Latitude: latitude,
			Longitude: longitude,
		}
	);
}

export default new CheckOutService();