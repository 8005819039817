import actionTypes from "./placeOrder.actionTypes";

const placeOrderLoadStart = () => ({
	type: actionTypes.PLACE_ORDER_LOAD_START,
});

const placeOrderLoadSuccess = (checkOutData) => ({
	type: actionTypes.PLACE_ORDER_SUCCESS,
	payload: checkOutData,
});

const placeOrderLoadError = (errorMessage) => ({
	type: actionTypes.PLACE_ORDER_ERROR,
	payload: errorMessage,
});

export default {
	placeOrderLoadStart,
	placeOrderLoadSuccess,
	placeOrderLoadError,
};
