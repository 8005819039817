import actionTypes from "./orderSummary.actionTypes";

const orderSummaryLoadingStart = () => ({
	type: actionTypes.ORDER_SUMMARY_LOADING_START,
});

const orderSummaryLoadingSuccess = (orderSummaryResponse) => ({
	type: actionTypes.ORDER_SUMMARY_SUCCESS,
	payload: orderSummaryResponse,
});

const orderSummaryLoadingError = (errorMessage) => ({
	type: actionTypes.ORDER_SUMMARY_ERROR,
	payload: errorMessage,
});

export default {
	orderSummaryLoadingStart,
	orderSummaryLoadingSuccess,
	orderSummaryLoadingError,
};