import PlaceOrderService from "../../../services/placeOrder.service";
import actions from "./placeOrder.actions";
import Cookies from 'universal-cookie';
import { checkOutAsync } from "../checkout/checkout.thunks";

export const placeOrderAsync = (
	addressID,
	isSuggestedDelivery,
	strSuggestedDate,
	strRemark,
	blnIsShopVisit,
	blnIsGuest,
	strName,
	strPhone,
	strEmail,
	strAddress) => (dispatch) => {

		dispatch(actions.placeOrderLoadStart());
		const cookies = new Cookies();

		PlaceOrderService.confirmPlaceOrder(
			cookies.get('sc_id'),
			cookies.get('sd_auth'),
			cookies.get('_scid'),
			"Browser",
			cookies.get('_shopID'),
			addressID,
			isSuggestedDelivery,
			strSuggestedDate,
			strRemark,
			blnIsShopVisit,
			blnIsGuest,
			strName,
			strPhone,
			strEmail,
			strAddress,
			"1",
			cookies.get('_lat'),
			cookies.get('_long'))
			.then((response) => {
				console.log(response);
				dispatch(actions.placeOrderLoadSuccess(response.data));
				dispatch(checkOutAsync(0));
			})
			.catch((error) => dispatch(actions.placeOrderLoadError(error.message)));
	};