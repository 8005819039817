import actionTypes from "./common.actionTypes";
import initialState from "./common.initialState";

const commonReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SET_SEARCH_KEYWORD:
			return {
				...state,
				searchQuery: "",
			  }

		default:
			return state;
	}
};

export default commonReducer;