import { combineReducers } from "redux";
import shopReducer from "./shop/shop.reducer";
import commonReducer from "./common/common.reducer";
import flyerReducer from "./flyer/flyer.reducer";
import productsReducer from "./products/products.reducer";
import singleProductReducer from "./loadSingleProducts/loadSingleProducts.reducer";

import checkOutReducer from "./checkout/checkout.reducer";
import placeOrderReducer from "./placeOrder/placeOrder.reducer";

import loginReducer from "./auth/login/login.reducer";
import sendCodeReducer from "./auth/sendCode/sendCode.reducer";
import signUpReducer from "./auth/signUp/signUp.reducer";

import listAddressReducer from "./address/listAddress/listAddressReducer.reducer";

import orderSummaryReducer from "./report/orderSummary/orderSummary.reducer";
import orderDetailsReducer from "./report/orderDetails/orderDetails.reducer";

const rootReducer = () =>

	combineReducers({
		commonReducer: commonReducer,
		shopReducer: shopReducer,
		flyerReducer: flyerReducer,
		productsReducer: productsReducer,
		loginReducer: loginReducer,
		checkOutReducer: checkOutReducer,
		placeOrderReducer:placeOrderReducer,
		sendCodeReducer: sendCodeReducer,
		signUpReducer: signUpReducer,
		listAddressReducer: listAddressReducer,
		orderSummaryReducer: orderSummaryReducer,
		orderDetailsReducer: orderDetailsReducer,
		singleProductReducer: singleProductReducer,
	});

export default rootReducer;