import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from 'react-owl-carousel3';
import { connect } from 'react-redux';
import ProductBox from '../home/ProductBox';
import CloseIcon from '@mui/icons-material/Close';
import { loadProductsAsync } from "../../redux/reducers/products/products.thunks";

const CategoriesCarousel = (props) => {

	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
	const dispatch = useDispatch();

	const handleCategoryClick = (cat) => {
		dispatch(loadProductsAsync(shop.ShopDetails.ShopID, cat.CategoryID, "", true, "", "", ""));
	}

	return (
		<>
			{shop &&
				<OwlCarousel loop {...options} className="owl-carousel-category owl-theme">
					{shop.CategoryList.map(category => {
						return (
							<div className="item">
								<ProductBox
									key={category.CategoryID}
									// onClick={() => props.handleCategoryClick(category)}
									// onClick={alert("hai")}
									// onClick={(props) => { this.props.removeTaskFunction(console.log("clicked")) }}
									// onClick={() => { this.props.handleCategoryClick(category)}}
									// onClick={() => { alert("clicked") }}
									onClick={() => { handleCategoryClick(category) }}
									boxClass='osahan-category-item'
									title={category.Name}
									// counting={category.CategoryID}

									image={category.ImagePath ? category.ImagePath : 'https://img.icons8.com/material-rounded/344/diversity.png'}
									imageClass='img-fluid'
									imageAlt=''

								/>
							</div>
						)
					}
					)}

				</OwlCarousel>
			}
		</>
	);
}

const options = {
	responsive: {
		0: {
			items: 5,
		},
		600: {
			items: 5,
		},
		1000: {
			items: 6,
		},
		1200: {
			items: 6,
		},
	},
	loop: false,
	lazyLoad: false,
	autoplay: false,
	dots: true,
	autoplaySpeed: 1000,
	autoplayTimeout: 1000,
	autoplayHoverPause: true,
	nav: false,
	navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
}

// const mapStateToProps=(state)=>({
// 	error: state.shopReducer.error,
// 	shop: state.shopReducer.shop
// })

// const mapDispatchToProps=(dispatch)=>({

// }) 

//export default connect(mapStateToProps, mapDispatchToProps)(CategoriesCarousel);
export default CategoriesCarousel;