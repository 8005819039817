import actionTypes from "./signUp.actionTypes";

const signUpLoadingStart = () => ({
	type: actionTypes.SIGN_UP_LOAD_START,
});

const signUpSuccess = (signUpData) => ({
	type: actionTypes.SIGN_UP_SUCCESS,
	payload: signUpData,
});

const signUpError = (errorMessage) => ({
	type: actionTypes.SIGN_UP_ERROR,
	payload: errorMessage,
});

export default {
	signUpLoadingStart,
	signUpSuccess,
	signUpError,
};