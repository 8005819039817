import actionTypes from "./orderSummary.actionTypes";
import initialState from "./orderSummary.initialState";

const orderSummaryReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.ORDER_SUMMARY_LOADING_START:
			return {
				...state,
				isOrderSummaryLoading: true,
				orderSummaryResponse: null,
				orderSummaryError: null,
			};

		case actionTypes.ORDER_SUMMARY_SUCCESS:
			return {
				...state,
				isOrderSummaryLoading: false,
				orderSummaryResponse: payload,
			};

		case actionTypes.ORDER_SUMMARY_ERROR:
			return {
				...state,
				isOrderSummaryLoading: false,
				orderSummaryError: payload,
			};

		default:
			return state;
	}
};

export default orderSummaryReducer;