import OrderService from "../../../../services/orders/orders.service"
import actions from "./orderSummary.actions";
import Cookies from 'universal-cookie';

export const loadOrderSummaryList = () => (dispatch) => {

	const cookies = new Cookies();

	dispatch(actions.orderSummaryLoadingStart());

	OrderService.getOrderList(
		cookies.get('sc_id'),
		cookies.get('sd_auth'),
		cookies.get('_shopID'))
		.then((response) => dispatch(actions.orderSummaryLoadingSuccess(response.data)))
		.catch((error) => dispatch(actions.orderSummaryLoadingError(error.message)));
};