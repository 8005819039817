import { useContext, useState } from 'react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


import { Row, Col, Container, Image, Badge } from 'react-bootstrap';
import Icofont from 'react-icofont';
import OwlCarousel from 'react-owl-carousel3';
import Typography from '@mui/material/Typography';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import Chip from '@mui/material/Chip';

import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import { useDispatch, useSelector } from "react-redux";

import { loadProductsAsync } from "../redux/reducers/products/products.thunks";
import { loadSingleProduct } from "../redux/reducers/loadSingleProducts/loadSingleProducts.thunks";

import { cartUpdateAsync } from "../redux/reducers/cartUpdate/cartUpdate.thunks";

import BestSeller from './common/BestSeller';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import Paper from '@mui/material/Paper';
import { useParams } from "react-router-dom";
import { withRouter } from 'react-router-dom';



const ItemDetail = ({ props }) => {

    const [showSuccessBar, setShowSuccessBar] = useState(false);
    const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
    const { isProductLoading, products, productsErrorMessage } = useSelector((state) => state.productsReducer);
    const { isSingleProductLoading, singleProduct, singleProductPricing } = useSelector((state) => state.singleProductReducer);

    const [itemQuantity, setItemQuantity] = useState(0);

    const dispatch = useDispatch();

    const { shopCode } = useParams();
    console.log(shopCode);

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('p_id');

    useEffect(() => {
        if (shop) {           
            dispatch(loadSingleProduct(id));
        }
    }, [shop])


    useEffect(() => {
        if (shop) {
           
            if (!products) {
                dispatch(loadProductsAsync(shop.ShopDetails.ShopID, 0, "", true, "", "", ""));
            }
        }
    }, [shop])



    // useEffect(() => {
    //     if (shop) {
    //         if (singleProductPricing) {
    //             dispatch(loadProductsAsync(shop.ShopDetails.ShopID, 0, "", true, "", "", ""));
    //         }
    //     }
    // }, [singleProductPricing])

    // function updateCart(quantity, isAdding) {
    //     const queryParams = new URLSearchParams(window.location.search);
    //     const id = queryParams.get('p_id');
    //     dispatch(cartUpdateAsync(id, quantity, 1, isAdding));
    // }

    function updateCart(stockID, quantity) {
		dispatch(cartUpdateAsync(stockID, quantity, 1, true));
	}

    const IncrementItem = () => {
        console.log("added")
        setItemQuantity(itemQuantity + 1)
        updateCart(id, itemQuantity + 1);
    };

    const DecreaseItem = () => {
        console.log("hai")
        if (itemQuantity > 0) {
            setItemQuantity(itemQuantity - 1)
            updateCart(id, itemQuantity - 1);
        }
    };

    return (
        <>
            <section style={{ marginTop: 120 }}>
            </section>

            <section className="p-5" >

                <Row className="d-flex ">

                    {singleProduct && <>
                        {singleProduct.Data.map(productRow => {

                            console.log(itemQuantity)

                            if (productRow.StockID = 30567) {

                                return (

                                    <> <Col md={3}>
                                        <div className="bg-white p-4  mb-2" style={{ textAlign: 'center' }}>

                                            <Image className="img-fluid" src={productRow.ImageData[0].ImagePath} alt="404" width="40%" />

                                            <OwlCarousel loop {...options} className="owl-carousel-category owl-theme">

                                                {productRow.ImageData.map(imageRow => {
                                                    <div className="item">
                                                        <Image className="osahan-category-item" src={imageRow.ImagePath} alt="404" />
                                                    </div>
                                                })}

                                            </OwlCarousel>
                                        </div>
                                    </Col>

                                        <Col md={6} className="pb-4">

                                            <div className="bg-white p-4  mb-2">

                                                <Typography variant="h6" component="div">
                                                    {productRow.ItemName}
                                                </Typography>

                                                <Typography variant="subtitle1" gutterBottom component="div">
                                                    {productRow.CategoryData.map(categoryData => {
                                                        <>
                                                            {categoryData.CategoryName}
                                                        </>
                                                    })}
                                                </Typography>

                                                {productRow.Discount > 0 ? <>
                                                    <Badge >Limited Offer</Badge>
                                                </> : <>
                                                </>}

                                                <Typography variant="h6" component="div">
                                                    AED {productRow.SellingPrice.toFixed(2).toString()}
                                                    {productRow.Discount > 0 ? <>
                                                        <span style={{ marginLeft: 10 }}>
                                                            <s>AED  {productRow.ActualPrice.toFixed(2).toString()}</s>
                                                        </span>
                                                        <p style={{ color: 'green', marginTop: 4 }}> <LocalOfferIcon style={{ marginRight: 3 }} />{productRow.Discount} % Off</p>
                                                    </> : <>
                                                    </>}
                                                </Typography>

                                                <Typography variant="subtitle1" gutterBottom component="div" gutterTop>
                                                    Specification
                                                </Typography>

                                                <Typography variant="body1" gutterBottom>
                                                    {productRow.Description}
                                                </Typography>

                                                <div style={{ marginTop: 20 }} >
                                                    {(itemQuantity == 0) ?
                                                        <Button variant="outlined" onClick={IncrementItem} className="btn btn-lg" style={{ width: "auto" }}> <AddShoppingCartIcon />  Add To Cart</Button>
                                                        :
                                                        <div className="addbuttondiv" style={{ minWidth: '50%' }}>
                                                            <span className="count-number float-left">
                                                                <Button variant="outline-secondary" onClick={DecreaseItem} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
                                                                <input className="count-number-input" type="text" value={itemQuantity} readOnly />
                                                                <Button variant="outline-secondary" onClick={IncrementItem} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>


                                                {/* <div style={{ marginTop: 20 }} >
                                                <Chip icon={<ShareIcon />} clickable label="Share" style={{ marginLeft: 5 }} />
                                                <Chip icon={<FacebookIcon />} clickable label="Facebook" />
                                                <Chip icon={<InstagramIcon />} clickable label="Instagram" style={{ marginLeft: 5 }} />
                                            </div> */}

                                            </div>

                                        </Col>
                                    </>
                                )
                            }
                        }
                        )}
                    </>}

                </Row>              
            </section>

        </>
    );
};

const options = {
    responsive: {
        0: {
            items: 4,
        },
        600: {
            items: 4,
        },
        1000: {
            items: 6,
        },
        1200: {
            items: 6,
        },
    },
    loop: false,
    lazyLoad: true,
    autoplay: true,
    dots: false,
    autoplaySpeed: 1500,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
}

export default ItemDetail;