import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Icofont from 'react-icofont';

class OrderCard extends React.Component {

	render() {
		return (
			<div className="bg-white card mb-4 shadow-sm">
				<div className="gold-members p-4">
					<div class="media">
						<div className="media-body">

							<h4 className="mb-2">
								<Link to={this.props.detailLink} className="text-black">  Order No  <strong>#{this.props.orderTitle}</strong></Link>
							</h4>
							<p className="text-gray">
								<Icofont icon="clock-time" className="ml-2" /> Ordered On : {this.props.orderDate}
							</p>

							<p className="text-dark">
								<Icofont icon="clock-time" className="ml-2" /> {this.props.orderStatus}
							</p>

							{/* {this.props.deliveredDate ?
								(
									<span className="float-right text-info">Delivered on {this.props.deliveredDate}
										<Icofont icon="check-circled" className="text-success ml-1" />
									</span>
								)
								: ""
							} */}

							<p className="text-gray">
								<Icofont icon="location-arrow" /> {this.props.address}
							</p>

							<p className="text-dark">
								Number of items : {this.props.numberOfItems}
							</p>

							<hr />
							<div className="float-right">
								<Link className="btn btn-sm btn-primary" to={this.props.detailLink}><Icofont icon="icofont-eye-alt" /> Details</Link>
								<Link className="btn btn-sm btn-outline-primary mr-1" to={this.props.helpLink}><Icofont icon="headphone-alt" /> HELP</Link>
							</div>
							<h5 className="mb-0 text-black text-primary pt-2">
								<span className="text-black font-weight-bold"> Order Amount: </span> {this.props.orderTotal.toFixed(2).toString()} AED
							</h5>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

OrderCard.propTypes = {
	image: PropTypes.string.isRequired,
	imageAlt: PropTypes.string,
	orderNumber: PropTypes.string.isRequired,
	orderDate: PropTypes.string.isRequired,
	deliveredDate: PropTypes.string,
	orderTitle: PropTypes.string.isRequired,
	orderStatus: PropTypes.string.isRequired,
	address: PropTypes.string.isRequired,
	numberOfItems: PropTypes.string.isRequired,
	helpLink: PropTypes.string.isRequired,
	detailLink: PropTypes.string.isRequired,
	orderTotal: PropTypes.string.isRequired,
};
export default OrderCard;