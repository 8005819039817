import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { loadFlyerAsync } from "../redux/reducers/flyer/flyer.thunks";
import { loadProductsAsync } from "../redux/reducers/products/products.thunks";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { ImageViewer } from "react-image-viewer-dv"
import { useParams } from "react-router-dom";
import Lightbox from 'react-lightbox-component';

import ImageGallery from 'react-image-gallery';
import Gallery from "react-photo-gallery";

const Flyers = (props) => {

	const dispatch = useDispatch();
	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
	const { isFlyerLoading, flyer, flyerErrorMessage } = useSelector((state) => state.flyerReducer);

	const { shopCode } = useParams();
	console.log(shopCode);

	useEffect(() => {
		if (shop) {
			dispatch(loadFlyerAsync(shop.ShopDetails.ShopID, "", ""));
		}
	}, [shop])

	 
const photos = [
	{
		src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
		width: 4,
		height: 3
	  },
	  {
		src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
		width: 1,
		height: 1
	  }
  ];

	return (
		<>
			<section style={{ marginTop: 130 }}>
			</section>


			{flyer && flyer.FlyerDatas.length == 0 ? <>

				<section className="section pt-5 pb-5 osahan-not-found-page">
					<Container>
						<Row>
							<Col md={12} className="text-center pt-5 pb-5">
								<h1 className="mt-2 mb-2">No Flyers found</h1>
								<p>Hmm.. No active flyers for today, Please check for tomorrow<br />Please browse our products</p>
								<Link className="btn btn-primary btn-lg" to={"/" + shopCode + "/"}>Browse All Products</Link>
							</Col>
						</Row>
					</Container>
				</section>


			</> : <>
				{/* <section className="section pt-5 pb-5 bg-white">



					{isFlyerLoading ? <ShimmerSimpleGallery card imageHeight={20} caption /> :
						<Container>
							{flyer.FlyerDatas.map(flyerRow => (

								<>

									<Row>
										{flyerRow.FlyerImages.map(flyerimage => (

											<>

												<Col md={4} className="text-center pt-5 pb-5">

													<ImageListItem key={flyerimage.FlyerDetailID} >
														<ImageViewer>
															<img
																src={`${flyerimage.ImagePath}?w=164&h=164&fit=crop&auto=format`}

																alt={flyerRow.OfferName}
																loading="lazy"
															/>
														</ImageViewer>
														<ImageListItemBar
															title={flyerRow.OfferName}
															subtitle={`views ${flyerRow.Viewers}`}
														/>
													</ImageListItem>

												</Col>


											</>
										))}


									</Row>


								</>
							))}
						</Container>
					}

				</section> */}



				<section className="section pb-5 bg-white">

					{isFlyerLoading ? <ShimmerSimpleGallery card imageHeight={20} caption /> :
						<>
							<ImageList  sx={{ width: 400}} cols={1} rowHeight={300}>
								{flyer.FlyerDatas.map(flyerRow => (
									<>
										{flyerRow.FlyerImages.map(flyerimage => (
											<>
												<ImageListItem key={flyerimage.imageid}>
													<ImageViewer>
														<img
															src={`${flyerimage.ImagePath}?w=164&h=164&fit=crop&auto=format`}
															srcSet={`${flyerimage.ImagePath}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
															alt={flyerRow.OfferName}
															loading="lazy"
														/>
													</ImageViewer>
												</ImageListItem>
											</>
										))}
									</>
								))}
							</ImageList>
						</>
					}

{/* <Gallery photos={photos} />; */}
				</section>
			</>}
		</>
	);
}

export default Flyers;