import apiClient from "../../helpers/apiClient";

class SignUpService {

	signUp = (messageID = 0, verificationCode = "", userName = "", userMobile = "", userEmail = "", password = "",
		firebaseToken = "", browserType = "", browserModel = "", browserVersion = "", appVersion = "", referralCode = "") => apiClient().post('/CustomerSignUpWithOTP',

			{
				MessageID: messageID,
				VerificationCode: verificationCode,
				Name: userName,
				Mobile: userMobile,
				Email: userEmail,
				Password: password,
				Token: firebaseToken,
				Device: "browseType",
				Model: "browserModel",
				OSVersion: "browserVersion",
				AppVersion: "appVersion",
				RCode: 0
			}
		);
}

export default new SignUpService();