import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Badge, Button, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { Toast } from 'react-bootstrap';
import { cartUpdateAsync } from "../../redux/reducers/cartUpdate/cartUpdate.thunks";
import { useDispatch, useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";

class BestSeller extends React.Component {
  constructor(props) {
    //this.updateCart = this.updateCart.bind(this)
    super(props);
    // this.props.updateCart.bind(this)
    this.state = {
      quantity: this.props.qty || 0,
      show: this.props.show || true,
      max: this.props.maxValue || 20,
      min: this.props.minValue || 0,
    };
  }

  IncrementItem = () => {
    if (this.state.quantity >= this.state.max) {

    } else {
      this.setState({
        quantity: this.state.quantity + 1
      });
      this.props.updateCart(this.props.id, (this.state.quantity + 1));
      // this.props.getValue({ id: this.props.id, quantity: (this.state.quantity + 1) });

      //   this.updateCartWish(this.props.id, this.state.quantity)
      // this.updateCart();
    }
  }

  DecreaseItem = () => {
    if (this.state.quantity <= (this.state.min)) {

    } else {
      this.setState({ quantity: this.state.quantity - 1 });
      // this.props.getValue({ id: this.props.id, quantity: (this.state.quantity - 1) });
      this.props.updateCart(this.props.id, (this.state.quantity - 1));
    }
  }

  updateCartWish = (stockID, qty) => {
    this.props.updateCart();
  }

  encryptWithPassphraseParams(plaintext) {
    const passphrase = CryptoJS.enc.Utf8.parse('haidearhackerobs')
    var encrypted = CryptoJS.AES.encrypt(plaintext, passphrase, { mode: CryptoJS.mode.ECB });
    // console.log("Plain:" + plaintext);
    // console.log("Encrypt:" + encrypted.ciphertext);
    this.decrypt((encrypted));
    this.decrypt((encrypted.ciphertext));
    this.decrypt((encrypted.ciphertext).toString());
    return (encrypted.ciphertext).toString();
    // this.decrypt(encrypted.ciphertext.toString())
  }

  decrypt(plaintext) {

    var passphrase = CryptoJS.enc.Utf8.parse('haidearhackerobs');

    // plaintext = plaintext.toString(CryptoJS.enc.Base64)
    var decrypted = CryptoJS.AES.decrypt(plaintext, 'haidearhackerobs', { mode: CryptoJS.mode.ECB });
  }


  render() {

    return (
      <div className="list-card bg-white shadow-sm h-100 rounded overflow-hidden position-relative shadow-sm">
        {/* {this.EncryptHex("30567")} */}

        {/* {this.decrypt('e325277f119777b155787aa9ea54618c', 'haidearhackerobs')} */}

        <div className="list-card-image">

          <Link to={"/" + this.props.shopCode + "/product/" +
            this.encryptWithPassphraseParams(this.props.id.toString()) +
            "?type=product&p_id=" + this.props.id.toString() + "&pid=" +
            this.encryptWithPassphraseParams(this.props.id.toString()) +
            "&name=" + (this.props.title).replace(' ', '-')} >
            <Image src={this.props.image} className={this.props.imageClass} alt={this.props.imageAlt} maxWidth='75%' style={{ borderRadius: 4, boxShadow: "0 3px 5px rgb(0 0 0 / 5%)" }} />
          </Link>
        </div>
        <div className="p-3 position-relative">
          <div className="list-card-body">

            <Typography variant="body2">
              <Link  to={"/" + this.props.shopCode + "/product/" +
                this.encryptWithPassphraseParams(this.props.id.toString()) +
                "?type=product&p_id=" + this.props.id.toString() + "&pid=" +
                this.encryptWithPassphraseParams(this.props.id.toString()) +
                "&name=" + (this.props.title).replace(' ', '-')} className="text-black">
{/* 
                {this.props.title.trim().length > 15 ? (
                  <>{this.props.title.substring(0, 15) + ".."}</>
                )
                  : <>{this.props.title}</>
                } */}

<>{this.props.title}</>

              </Link>
            </Typography>

            {this.props.subTitle ? (
              <p className="text-gray mb-3">{this.props.subTitle}</p>
            )
              : ''
            }

            {(this.props.offerprice) ? (<Badge variant="success" className='ml-1'>NEW</Badge>) : ""}

            <Typography variant="subtitle2" className="text-black" component="div">
              <span>

                <>
                  {(this.props.price != this.props.oldPrice) ?
                    (<>
                      <span style={{ color: 'grey' }}> <s className="text-grey">{this.props.oldPrice.toFixed(2).toString()}</s></span>    <span style={{ color: 'green' }}>{this.props.discountPercentage}% off</span>
                    </>
                    ) : <></>}
                </>
              </span>
            </Typography>


            <Typography variant="subtitle2" className="text-black" component="div">
              <span>
                <>
                  {(this.props.price) ? (
                    <>
                      {this.props.priceUnit}{this.props.price.toFixed(2).toString()} </>
                  ) : <></>
                  }

                </>
              </span>
            </Typography>


            {(this.props.price > 0) ? (
              <p className="text-gray time mb-0">
                {this.state.quantity === 0 ?
                  <div className="addbuttondivss" style={{ textAlign: 'right', minWidth: '50%' }}>
                    <span >
                      <Button variant='outline-secondary' onClick={this.IncrementItem} size="sm">ADD</Button>
                    </span>
                  </div>
                  :
                  <div className="addbuttondiv" style={{ textAlign: 'right', minWidth: '50%' }}>
                    <span className="count-number float-left">
                      <Button variant="outline-secondary" onClick={this.DecreaseItem} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
                      <input className="count-number-input" type="text" value={this.state.quantity} readOnly />
                      <Button variant="outline-secondary" onClick={this.IncrementItem} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
                    </span>
                  </div>
                }
              </p>
            ) : ''
            }
          </div>
        </div >
      </div >
    );
  }
}


BestSeller.propTypes = {
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  isNew: PropTypes.bool,
  subTitle: PropTypes.string,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  showPromoted: PropTypes.bool,
  promotedVariant: PropTypes.string,
  favIcoIconColor: PropTypes.string,
  rating: PropTypes.string,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired,
  oldPrice: PropTypes.number,
  discountPercentage: PropTypes.number,
  shopID: PropTypes.number,
};
BestSeller.defaultProps = {
  imageAlt: '',
  imageClass: '',
  isNew: false,
  subTitle: '',
  price: '',
  priceUnit: '$',
  showPromoted: false,
  promotedVariant: 'dark',
  favIcoIconColor: '',
  rating: '',
  shopID: 0,
  discountPercentage: 0
}

export default BestSeller;