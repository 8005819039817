import apiClient from "../../helpers/apiClient";

class LoginService {
	getLoginDetails = (mobileNumber, password, pushNotificationToken) => apiClient().post('/CustomerLogin', {

		Mobile: mobileNumber,
		Password: password,
		Token: pushNotificationToken,
		Device: "Website",
		Model: "React",
		OSVersion: "01",
		AppVersion: "01"
	});
}

export default new LoginService();