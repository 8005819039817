import apiClient from "../../helpers/apiClient";

class SendVerificationCodeService {

	sendVerificationCode = (mobileNumber = "", email = "") => apiClient().post('/CheckCusSignUpOTP',
		{
			Phone: mobileNumber,
			Email: email,
			Type: 1
		}
	);
}

export default new SendVerificationCodeService();