import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loadShopAsync } from "../../redux/reducers/shop/shop.thunks";
import { loadLoginAsync } from "../../redux/reducers/auth/login/login.thunks";
import { ShimmerCircularImage, ShimmerBadge } from "react-shimmer-effects";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { blue, purple, white } from '@mui/material/colors';
import { Helmet } from "react-helmet";

import InputAdornment from '@mui/material/InputAdornment';


import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { Link } from 'react-router-dom';
import { Row, Col, Container, Form, InputGroup, FormControl } from 'react-bootstrap';
import FontAwesome from '../common/FontAwesome';

import { GoogleLogin, GoogleLogout } from 'react-google-login';
import Modal from '@mui/material/Modal';
import { fetchToken, onMessageListener } from '../../firebase';
import { Toast } from 'react-bootstrap';
import { loadProductsAsync } from "../../redux/reducers/products/products.thunks";
import { sendCodeAsync } from "../../redux/reducers/auth/sendCode/sendCode.thunks";
import { signUpAsync } from "../../redux/reducers/auth/signUp/signUp.thunks";

import Cookies from 'universal-cookie';

import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';

import OtpInput from "react-otp-input";
// import OTPInput, { ResendOTP } from "otp-input-react";
// import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";


import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import { useParams } from "react-router-dom";

import { refreshTokenSetup } from '../../utils/refreshToken';


const clientId = "43057026924-cp0b4hlsremi65ra8mlm8dlc28273j44.apps.googleusercontent.com";
//J6aoMyTPa7QexFS1J1eC1_Rv

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: '15px',
	maxWidth: '450px',
};


const Header = (props) => {

	const location = useLocation();
	const dispatch = useDispatch();

	const { shopCode } = useParams();
	// console.log(shopCode);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);

	const { isLoginLoading, loginData, loginErrorMessage } = useSelector((state) => state.loginReducer);
	const { isSendCodeLoading, sendCodeData, sendCodeErrorMessage } = useSelector((state) => state.sendCodeReducer);
	const { isSignUpLoading, signUpData, signUpError } = useSelector((state) => state.signUpReducer);

	const [open, setOpen] = React.useState(false);
	const [snackBarMessage, setSnackBarMessage] = React.useState("");

	const [isLoggedIn, setLoggedIn] = React.useState(false);
	const [loginModelOpen, setLoginModelOpen] = React.useState(false);
	const [emailOrPhone, loginPassword] = React.useState('');
	const [emailError, passwordError, generalLoginError] = React.useState('');



	const [openLoginModal, setOpenLoginModal] = React.useState(false);
	const [openSignUpModal, setOpenSignUpModal] = React.useState(false);
	const [openVerificationModal, setOpenVerificationModal] = React.useState(false);

	const setSignUpPhoneError = (newValue) => {
		SignUpPhoneError(newValue);
	};

	const setSignUpPasswordError = (newValue) => {
		signUpPasswordError(newValue);
	};

	const handleLoginOpen = () => {
		setOpenLoginModal(true);
		setOpenSignUpModal(false);
		setOpenVerificationModal(false);
	};


	const handleLoginClose = () => setOpenLoginModal(false);

	const handleSignUpOpen = () => {
		setOpenLoginModal(false);
		setOpenSignUpModal(true);
	};

	const handleCloseAllModal = () => {
		setOpenLoginModal(false);
		setOpenSignUpModal(false);
		setOpenVerificationModal(false);
	};

	const handleVerificationModalOpen = () => {
		setOpenLoginModal(false);
		setOpenSignUpModal(false);
		setOpenVerificationModal(true);
	};

	const cookies = new Cookies();

	if (!cookies.get('_scid')) {
		// console.log("no cookie");
		cookies.set('_scid', makeID(30), { path: '/' });
		// console.log(cookies.get('sc_session_id'));
	} else {
		// console.log("cookie found");
		// console.log(cookies.get('_scid'));
	}


	const theme = createTheme({
		palette: {
			primary: {
				main: (shop != null ? shop.ShopDetails.ThemeColor : '#ffff'),
			},
			secondary: {
				main: blue[500],
			},
		},
	});


	const handleSearch = (value) => {
		// alert(value);
		// alert(shop.ShopDetails.ShopID);
		dispatch(loadProductsAsync(shop.ShopDetails.ShopID, 0, value, true, "", "", ""));
	}

	const showSnackBarMessage = (message) => {
		setOpen(true);
		setSnackBarMessage(message);
	};


	const updateUserName = (value) => {
		setUserName(value);
	}

	const updateUserMobile = (value) => {
		setSignUpPhoneError('');
		setUserMobile(value);
	}

	const updateUserEmail = (value) => {
		setUserEmail(value);
	}

	const updatePassword = (value) => {
		setPassword(value);
	}


	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	useEffect(() => {

		if (!shop) {
			dispatch(loadShopAsync(shopCode, '', ''));
		}

		if (navigator.permissions && navigator.permissions.query) {
			//try permissions APIs first
			navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
				// Will return ['granted', 'prompt', 'denied']
				const permission = result.state;
				if (permission === 'granted' || permission === 'prompt') {
					_onGetCurrentLocation();
				}
			});
		} else if (navigator.geolocation) {
			//then Navigation APIs
			_onGetCurrentLocation();
		}

		function _onGetCurrentLocation() {
			navigator.geolocation.getCurrentPosition(function (position) {


				cookies.set('_lat', position.coords.latitude, { path: '/' });
				cookies.set('_long', position.coords.longitude, { path: '/' });
				//imitate map latlng construct
				// const marker = {
				// 	lat: position.coords.latitude,
				// 	lng: position.coords.longitude
				// };
			})
		}

	}, [])

	useEffect(() => {

		if (shop != null) {

			const { REACT_APP_API_URL, REACT_APP_API_REQUEST_HEADER_KEY } = process.env;

			var myDynamicManifest = {
				"name": shop.ShopDetails.Name,
				"short_name": shop.ShopDetails.Name,
				"description": "Shop products, offer and more",
				"start_url": "https://shop.singlecart.io/" + shopCode,
				"display": "standalone",
				"background_color": "#F5FFFA",
				"theme_color": "#0f4a73",
				"icons": [
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "57x57",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "57x57",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "60x60",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "60x60",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "72x72",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "72x72",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "76x76",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "76x76",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "114x114",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "114x114",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "120x120",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "120x120",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "144x144",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "144x144",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "152x152",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "152x152",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "180x180",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "180x180",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "192x192",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "192x192",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "32x32",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "32x32",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "96x96",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "96x96",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "16x16",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "16x16",
						"density": "1.0",
						"purpose": "maskable"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "512x512",
						"density": "1.0",
						"purpose": "any"
					},
					{
						"src": shop.ShopDetails.LogoPath,
						"type": "image/png",
						"sizes": "512x512",
						"density": "1.0",
						"purpose": "maskable"
					}
				]
			}
			const stringManifest = JSON.stringify(myDynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);

			document.querySelector('#my-manifest-placeholder').setAttribute('href', REACT_APP_API_URL + "Manifest/" + shopCode);
			document.querySelector('#apple_touch_icon_id').setAttribute('href', shop.ShopDetails.LogoPath);

			cookies.set('_shopID', shop.ShopDetails.ShopID, { path: '/' });
			cookies.set('shop_type', shop.ShopDetails.ShopType, { path: '/' });
		}

	}, [shop])

	const meta = {
		title: 'Samvikshana - New Perspective of Exploration',
		meta: {
			property: {
				'og:title': 'Samvikshana',
				'og:url': 'https://samvikshana.weebly.com/',
				'og:image': "",
				'og:description': 'New Perspective of Exploration',

				'twitter:card': 'summary_large_image',
				'twitter:title': 'Samvikshana',
				'twitter:description': 'New Perspective of Exploration',
				'twitter:image': ""
			}
		}
	};

	// function updateInput(event) {
	// 	this.setState({ username: event.target.value })
	// }

	const [showloginButton, setShowloginButton] = useState(true);
	const [showlogoutButton, setShowlogoutButton] = useState(false);
	const onLoginSuccess = (res) => {
		console.log('Login Success:', res.profileObj);
		setShowloginButton(false);
		setShowlogoutButton(true);
		refreshTokenSetup(res);
	};

	const onLoginFailure = (res) => {
		console.log('Login Failed:', res);
	};

	const onSignoutSuccess = () => {
		alert("You have been logged out successfully");
		console.clear();
		setShowloginButton(true);
		setShowlogoutButton(false);
	};

	function handleChange(event) {
		this.setState({ value: event.target.value });
	}

	function handleSubmit(event) {
		event.preventDefault();

		var { signInEmailPhone, signInPassword } = document.forms[0];
		dispatch(loadLoginAsync(signInEmailPhone.value, signInPassword.value, ""));
	}


	useEffect(() => {

		if (loginData != null) {

			if (loginData.Error == false) {

				cookies.set('sc_logged_in', "1", { path: '/' });
				cookies.set('sc_id', loginData.UserID, { path: '/' });
				cookies.set('sd_auth', loginData.SessionKey, { path: '/' });
				cookies.set('sc_name', loginData.CustomerName, { path: '/' });
				setLoggedIn(true);
			}
		}

		if (!cookies.get('sc_logged_in')) {
			// console.log("no cookie");
			cookies.set('sc_logged_in', "0", { path: '/' });
			// console.log(cookies.get('sc_session_id'));
		}

		if (cookies.get('sc_logged_in') == "1") {
			setLoggedIn(true);
		} else {
			setLoggedIn(false);
		}

	}, [loginData])


	useEffect(() => {


		if (loginData != null) {

			if (loginData.Error == false) {
				showSnackBarMessage("Hai " + loginData.CustomerName)
				handleCloseAllModal();
			} else {
				showSnackBarMessage(loginData.Message)
			}
		} else {

		}

	}, [loginData])

	var [signUpPhoneError, SignUpPhoneError] = React.useState('');
	var [signUpEmailError, setSignUpEmailError] = React.useState('');
	var [signUpPasswordError, signUpPasswordError] = React.useState('');


	var [userName, setUserName] = React.useState('');
	var [userMobile, setUserMobile] = React.useState('');
	var [userEmail, setUserEmail] = React.useState('');
	var [password, setPassword] = React.useState('');

	function handleSignUp(event) {
		event.preventDefault();

		setSignUpPhoneError('');
		setSignUpEmailError('');
		setSignUpPasswordError('');

		if (userMobile.trim().length != 9) {
			setSignUpPhoneError('Enter UAE 9 digit mobile number');
			return;
		}

		if (userEmail.trim().length < 7) {
			setSignUpEmailError('Enter a valid email address');
			return;
		}

		dispatch(sendCodeAsync(userMobile.trim(), userEmail.trim()));
	}

	//this function will run when signup code receives
	useEffect(() => {
		if (sendCodeData != null) {
			if (sendCodeData.Error == false) {
				showSnackBarMessage("Verification code send to the mobile number");
				handleVerificationModalOpen();
			} else {
				showSnackBarMessage(sendCodeData.Message)
			}
		}
	}, [sendCodeData])

	var [verificationCodeError, setVerificationCodeError] = React.useState('');
	function handleVerificationCode(event) {
		event.preventDefault();

		var { signUpVerificationCode } = document.forms[0];

		if (signUpVerificationCode.value.trim().length != 4) {
			setVerificationCodeError('Minimum 4 character required');
			return;
		}

		console.log("reached")
		dispatch(signUpAsync(sendCodeData.MessageID,
			signUpVerificationCode.value,
			userName,
			userMobile,
			userEmail,
			password));
		console.log("over")
	}

	//this function will run when signup code receives
	useEffect(() => {

		// console.log(signUpData)
		if (signUpData != null) {
			if (signUpData.Error == false) {

				showSnackBarMessage("Hai " + signUpData.CustomerName)
				cookies.set('sc_logged_in', "1", { path: '/' });
				cookies.set('sc_id', signUpData.UserID, { path: '/' });
				cookies.set('sd_auth', signUpData.SessionKey, { path: '/' });
				cookies.set('sc_name', signUpData.CustomerName, { path: '/' });
				setLoggedIn(true);
				handleCloseAllModal();
			} else {
				showSnackBarMessage(signUpData.Message)
			}
		}
	}, [signUpData])



	function makeID(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}

	return (
		<>
			{/* 
<DocumentMeta {...meta} /> */}

			<Helmet>
				<meta charSet="utf-8" />
				<title>{shop && shop.ShopDetails.Name}</title>
				<link id="favicon" rel="icon" href={shop && shop.ShopDetails.LogoPath} type="image/x-icon" />
			</Helmet>

			<ThemeProvider theme={theme}>

				<Box>
					<AppBar position="fixed" color="primary" >


						{/* {(isLoggedIn == false) ? <>

							<div style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px', float: 'right' }}>
								<Button onClick={handleLoginOpen} variant="contained" style={{ float: 'right' }}>Log In</Button>
							</div>
						</> : <>

						</>} */}

						<Toolbar>
							{isLoading ? (
								<>
									<ShimmerCircularImage size={30} />			<ShimmerBadge width={100} />
								</>
							) : (
								<>
									<Image style={{ height: 30, margin: 10, borderRadius: 4, boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }} src={shop ? shop.ShopDetails.LogoPath : "/img/logo.png"} alt='' />

									<Typography variant="subtitle1" component="div" style={{ maxWidth: '66%', flexGrow: 1 }}>
										{shop && " " + shop.ShopDetails.Name}
									</Typography>

									{/* {(isLoggedIn == false) ? <>
										<Button onClick={handleLoginOpen} color="inherit" style={{ float: 'right' }}>Login</Button>
									</> : <>
									</>} */}
								</>

							)}

						</Toolbar>
						{/* <Input placeholder='Search...' type="text" onChange={this.updateInput} className="form-control" style={{ marginLeft: 15, marginBottom: 20, width: '90%' }} /> */}
						{/* <Button onClick={handleLoginOpen} variant="contained" size="small" style={{ float: 'right' }}>Log In</Button> */}

						<Input onChange={(e) => handleSearch(e.target.value)} placeholder='Search...' className="form-control bg-white" style={{ marginLeft: 20, marginBottom: 10, width: '90%' }} />


						{/* <Button onClick={handleLoginOpen}>Open modal</Button> */}

					</AppBar>


				</Box>



			</ThemeProvider>

			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
				message={snackBarMessage}
				action={action}
			/>
			{/* Login Modal */}
			<Modal
				open={openLoginModal}
				onClose={handleLoginClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>

				<Box sx={style}>
					<Container fluid className='bg-white'>
						<Col>
							<div >
								<Container>
									<Row>
										<Col >
											<h3 >Welcome back!</h3>

											<GoogleLogin
												clientId={clientId}
												buttonText="Sign In with Google"
												onSuccess={onLoginSuccess}
												onFailure={onLoginFailure}
												cookiePolicy={'single_host_origin'}
												isSignedIn={true}
											/>

											{/* <Form onSubmit={handleSubmit}>

												<Box
													// component="form"
													sx={{
														'& > :not(style)': { m: .5, },
													}}
													validate
													autoComplete="off"
												>

													<div style={{ paddingTop: 20 }}>
														<TextField
															required

															id="signInEmailPhone"
															label="Mobile Number"
															type="number"
															InputProps={{
																startAdornment: <InputAdornment position="start">+971 </InputAdornment>,
															}}
															variant="outlined"
															helperText="Enter 9 digit UAE mobile number"
															fullWidth
														/>
													</div>
													<div style={{ paddingTop: 5 }}>
														<TextField
															id="signInPassword"

															label="Password"
															type="password"
															variant="outlined"
															fullWidth

														/>

													</div>
												</Box>

											


												<span className="text-danger" style={{ paddingTop: 5 }}>{generalLoginError}</span>
												<Button type="submit" variant="contained" style={{ minWidth: '-webkit-fill-available', marginTop: 10 }} >Sign in</Button>
												<div className="text-center pt-3">
													Don’t have an account?
													<Button onClick={handleSignUpOpen} variant="text" style={{ minWidth: '-webkit-fill-available' }} >Sign Up</Button>
												</div>


												<hr className="my-4" />
												<p className="text-center">LOGIN WITH</p>
												<div className="row">
													<div className="col pr-2">
														<GoogleLogin
															clientId={clientId}
															buttonText="Sign In with Google"
															onSuccess={onLoginSuccess}
															onFailure={onLoginFailure}
															cookiePolicy={'single_host_origin'}
															isSignedIn={true}
														/>
														<Button className="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase" type="submit"><FontAwesome icon="google" className="mr-2" /> Google</Button>
													</div>
													<div className="col pl-2">
														<Button className="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase" type="submit"><FontAwesome icon="facebook" className="mr-2" /> Facebook</Button>
													</div>
												</div>



												<hr className="my-2" />
												<Button onClick={handleLoginClose} variant="text" style={{ minWidth: '-webkit-fill-available' }} >Login Later</Button>
											</Form> */}


										</Col>
									</Row>
								</Container>
							</div>
						</Col>
					</Container>
				</Box>
			</Modal>

			{/* Sign Up Modal */}
			<Modal
				open={openSignUpModal}
				onClose={handleLoginClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">

				<Box sx={style}>
					<Container fluid className='bg-white'>
						<Col>
							<div >
								<Container>
									<Row>
										<Col >
											<h3 >Sign Up Here!</h3>
											<Form onSubmit={handleSignUp}>

												<div style={{ paddingTop: 20 }}>
													<TextField
														required
														id="signUpName"
														label="Name"
														type="text"
														variant="outlined"
														fullWidth
														placeholder='Enter your name'
														onChange={(e) => updateUserName(e.target.value)}
													/>
												</div>

												<div style={{ paddingTop: 20 }}>
													<TextField
														error={signUpPhoneError.trim().length > 0 ? true : false}
														required
														id="signUpPhone"
														label="Mobile Number"
														type="number"
														InputProps={{
															startAdornment: <InputAdornment position="start">+971 </InputAdornment>,
														}}
														variant="outlined"
														fullWidth
														helperText={signUpPhoneError.trim().length > 0 ? verificationCodeError.trim() : "Enter 9 digit UAE mobile number"}
														placeholder='Enter mobile number'
														onChange={(e) => updateUserMobile(e.target.value)}
													/>
												</div>

												<div style={{ paddingTop: 20 }}>
													<TextField
														required
														error={signUpEmailError.trim().length > 0 ? true : false}
														helperText={signUpEmailError}
														id="signUpEmail"
														label="Email"
														type="email"
														variant="outlined"
														fullWidth
														placeholder='Enter your email address'
														onChange={(e) => updateUserEmail(e.target.value)}
													/>
												</div>

												<div style={{ paddingTop: 20 }}>
													<TextField
														required
														id="signUpPassword"
														label="Password"
														type="password"
														variant="outlined"
														fullWidth
														placeholder='Enter a password'
														onChange={(e) => updatePassword(e.target.value)}
													/>
												</div>


												<div style={{ paddingTop: 20 }}>
													<TextField
														required
														id="signUpConfirmPassword"
														label="Confirm Password"
														type="password"
														variant="outlined"
														fullWidth
														placeholder='Re-Enter password'
														onChange={(e) => updatePassword(e.target.value)}
													/>
												</div>

												<span className="text-danger">{generalLoginError}</span>
												<Button type="submit" variant="contained" style={{ minWidth: '-webkit-fill-available', marginTop: 20 }} >Sign Up</Button>

												<div className="text-center pt-2 ">
													Already have an account?
													<Button onClick={handleLoginOpen} variant="text" style={{ minWidth: '-webkit-fill-available' }} >Sign In</Button>
												</div>
												<hr className="my-1" />
												<Button onClick={handleCloseAllModal} variant="text" style={{ minWidth: '-webkit-fill-available' }} >Sign Up Later</Button>
											</Form>
										</Col>
									</Row>
								</Container>
							</div>
						</Col>
					</Container>
				</Box>
			</Modal>

			{/* verification code */}
			<Modal
				open={openVerificationModal}
				onClose={handleLoginClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>

				<Box sx={style}>
					<Container fluid className='bg-white'>
						<Col>
							<div >
								<Container>
									<Row>
										<Col >
											<h4 >Verification code</h4>
											<Form onSubmit={handleVerificationCode}>

												<div style={{ paddingTop: 20 }}>
													<TextField
														required
														error={verificationCodeError.trim().length > 0 ? true : false}
														id="signUpVerificationCode"
														label="Verification Code"
														type="number"
														variant="outlined"
														helperText={verificationCodeError.trim().length > 0 ? verificationCodeError.trim() : "Verification Code send to your mobile"}
														fullWidth
													/>
												</div>

												<span className="text-danger">{generalLoginError}</span>
												<Button type="submit" className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" style={{ minWidth: '-webkit-fill-available', marginTop: 20 }}>Verify</Button>
											</Form>
										</Col>
									</Row>
								</Container>
							</div>
						</Col>
					</Container>
				</Box>
			</Modal>



		</>
	);
}
export default Header;

{/* <div style={{ margin: '10px' }}>
Please login to use enhanced features
<Button onClick={handleLoginOpen} variant="contained" >Log In</Button>
</div> */}