import actionTypes from "./login.actionTypes";
import initialState from "./login.initialState";

const loginReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.LOGIN_LOAD_START:
			return {
				...state,
				isLoginLoading: true,
				loginData: null,
				errorMessage: null,
			};

		case actionTypes.LOGIN_LOAD_SUCCESS:
			return {
				...state,
				isLoginLoading: false,
				loginData: payload,
			};

		case actionTypes.LOGIN_LOAD_ERROR:
			return {
				...state,
				isLoginLoading: false,
				errorMessage: payload,
			};

		default:
			return state;
	}
};

export default loginReducer;
