import actionTypes from "./listAddressReducer.actionTypes";
import initialState from "./listAddressReducer.initialState";

const listAddressReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.LIST_ADDRESS_LOADING_START:
			return {
				...state,
				isAddressListLoading: true,
				listAddressResponse: null,
				listAddressError: null,
			};

		case actionTypes.LIST_ADDRESS_SUCCESS:
			return {
				...state,
				isAddressListLoading: false,
				listAddressResponse: payload,
			};

		case actionTypes.LIST_ADDRESS_ERROR:
			return {
				...state,
				isAddressListLoading: false,
				listAddressError: payload,
			};

		default:
			return state;
	}
};

export default listAddressReducer;