import actionTypes from "./signUp.actionTypes";
import initialState from "./signUp.initialState";

const signUpReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SIGN_UP_LOAD_START:
			return {
				...state,
				isSignUpLoading: true,
				signUpData: null,
				signUpError: null,
			};

		case actionTypes.SIGN_UP_SUCCESS:
			return {
				...state,
				isSignUpLoading: false,
				signUpData: payload,
			};

		case actionTypes.SIGN_UP_ERROR:
			return {
				...state,
				isSignUpLoading: false,
				signUpError: payload,
			};

		default:
			return state;
	}
};

export default signUpReducer;