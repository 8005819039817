import apiClient from "../helpers/apiClient";

class ProductService {
  getProductList = (
    shopID,
    categoryID = 0,
    searchQuery = "",
    showNoOfferItems = true,
    latitude = "",
    longitude = ""
  ) =>
    apiClient().post("/GetProductsForWeb", {
      UserID: 0,
      AuthToken: "",
      CategoryID: categoryID,
      ShopID: shopID,
      ShopType: 0,
      StockID: 0,
      IsShowInstock: true,
      IsShowOutStock: true,
      IsShowRecommended: true,
      IsShowNonRecommended: true,
      IsOpen: true,
      IsClosed: true,
      IsShowOffers: true,
      IsNonShowOffers: showNoOfferItems,
      IsSortRank: false,
      IsSorting: true,
      IsPriceSorting: false,
      IsDiscountSorting: false,
      IsAscending: false,
      StartingPrice: 0,
      EndingPrice: 0,
      Name: searchQuery,
      PageSize: 50,
      PageNo: 1,
      Latitude: latitude,
      Longitude: longitude,
    });

  getSingleProduct = (stockID = 0, latitude = "", longitude = "") =>
    apiClient().post("/GetProductsForWeb", {
      UserID: 0,
      AuthToken: "",
      CategoryID: 0,
      ShopID: 0,
      ShopType: 0,
      StockID: stockID,
      IsShowInstock: true,
      IsShowOutStock: true,
      IsShowRecommended: true,
      IsShowNonRecommended: true,
      IsOpen: true,
      IsClosed: true,
      IsShowOffers: true,
      IsNonShowOffers: true,
      IsSortRank: false,
      IsSorting: true,
      IsPriceSorting: false,
      IsDiscountSorting: false,
      IsAscending: false,
      StartingPrice: 0,
      EndingPrice: 0,
      Name: "",
      PageSize: 1,
      PageNo: 1,
      Latitude: latitude,
      Longitude: longitude,
    });
}

export default new ProductService();
