import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { ShimmerCircularImage, ShimmerBadge, ShimmerButton, ShimmerSimpleGallery } from "react-shimmer-effects";
import { ImageViewer } from "react-image-viewer-dv"
import BestSeller from './common/BestSeller';
import { useDispatch, useSelector } from "react-redux";
import { loadFlyerAsync } from "../redux/reducers/flyer/flyer.thunks";
import { loadProductsAsync } from "../redux/reducers/products/products.thunks";
import { Image } from 'react-bootstrap';

import { useParams } from "react-router-dom";
import { cartUpdateAsync } from "../redux/reducers/cartUpdate/cartUpdate.thunks";


const Offer = (props) => {

	const dispatch = useDispatch();
	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
	const { isProductLoading, products, productsErrorMessage } = useSelector((state) => state.productsReducer);

	const { shopCode } = useParams();
	console.log(shopCode);

	useEffect(() => {
		if (shop) {
			dispatch(loadProductsAsync(shop.ShopDetails.ShopID, 0, "", false, "", "", ""));

		}
	}, [shop])

	function updateCart(stockID, quantity) {
		dispatch(cartUpdateAsync(stockID, quantity, 1, true));
	}

	return (
		<>
			<section style={{ marginTop: 130 }}>
			</section>

			{products && products.Data.length == 0 ? <>

				<section className="section pt-5 pb-5 osahan-not-found-page">
					<Container>
						<Row>
							<Col md={12} className="text-center pt-5 pb-5">
								<h1 className="mt-2 mb-2">No active offers</h1>
								<p>Uh-oh! Looks like there is no offers found for today<br />Please browse our products</p>
								<Link className="btn btn-primary btn-lg" to={"/"+shopCode+"/"}>Browse All Products</Link>								
							</Col>
						</Row>
					</Container>
				</section>


			</> : <></>}



			<section className="section pt-1 pb-5 bg-white">

				{isProductLoading ? <ShimmerSimpleGallery card imageHeight={100} caption /> :
					<Row style={{ marginRight: 0, marginLeft: 0 }}>



						{products && <>
							{products.Data.map(productRow => {
								return (
									<Col md={2} xs={6}>

										{(productRow.SellingPrice != productRow.ActualPrice) ? <>

											<BestSeller
												{...props}
												updateCart={updateCart}
												id={productRow.StockID}
												title={productRow.ItemName}
												shopCode={shopCode}
												subTitle={productRow.OfferName}
												imageAlt='Product'
												image={productRow.ImageData[0].ImagePath}
												imageClass='img-fluid item-img'
												price={productRow.SellingPrice}
												priceUnit='AED '
												isNew={true}
												showPromoted={true}
												promotedVariant='dark'
												favIcoIconColor='text-danger'
												rating='3.1 (300+)'
												oldPrice={productRow.ActualPrice}
												discountPercentage={productRow.Discount}
											//getValue={this.getQty}
											/>

										</> : <>

											<BestSeller
												id={productRow.StockID}
												title={productRow.ItemName}
												subTitle={productRow.OfferName}
												imageAlt='Product'
												image={productRow.ImageData[0].ImagePath}
												imageClass='img-fluid item-img'
												price={productRow.SellingPrice}
												priceUnit='AED '
												isNew={false}
												showPromoted={false}
												promotedVariant='dark'
												favIcoIconColor='text-danger'
												rating='3.1 (300+)'
												oldPrice={productRow.ActualPrice}
											//getValue={this.getQty}
											/>

										</>}


									</Col>
								)
							}
							)}
						</>}
					</Row>
				}
			</section>
		</>
	);
}



export default Offer;
