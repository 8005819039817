import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Index from './components/Index';
import ItemDetail from './components/ItemDetail';
import Flyers from './components/Flyers';
import Offers from './components/Offers';
import Offer from './components/Offer';
import MyAccount from './components/MyAccount';
import List from './components/List';
import NotFound from './components/NotFound';
import Thanks from './components/Thanks';
import Extra from './components/Extra';
import Login from './components/Login';
import Register from './components/Register';
import TrackOrder from './components/TrackOrder';
import Invoice from './components/Invoice';
import Checkout from './components/Checkout';
import Detail from './components/Detail';
import MyAddress from './components/MyAddress';
import AddEditAddress from './components/AddEditAddress';
import MyOrderHistory from './components/MyOrderHistory';

import OrderDetails from './components/OrderDetails';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-select2-wrapper/css/select2.css';
import './App.css';
import { useEffect, useState } from 'react';

import { fetchToken, onMessageListener } from './firebase';
import { Button, Toast } from 'react-bootstrap';
import ReactGA from 'react-ga';



const App = (props) => {

  const location = useLocation();

  const TRACKING_ID = "3547287961"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);

  onMessageListener().then(payload => {
    console.log(payload);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    setShow(true);
  }).catch(err => console.log('failed: ', err));

  // getToken(setTokenFound);

  // onMessageListener().then(payload => {
  //   setShow(true);
  //   setNotification({title: payload.notification.title, body: payload.notification.body})
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({ title: "Notification", body: "This is a test notification" })
    setShow(true);
  }

  return (
    <>
      {/* {
        (location.pathname !== '/login' && location.pathname !== '/register') ? <Header {...props} /> : ''
      } */}
      <Routes>

        {/* <Route path="/:shopCode" exact="true" element={<> <Header {...props} />  <Index {...props} /> </>} /> */}

        <Route path="/:shopCode" exact="true" element={<><Header {...props} /> <Index {...props} /><Footer {...props} /> </>} />

        <Route path="/:shopCode/offers" exact="true" element={<><Header {...props} /> <Offer {...props} /><Footer {...props} /></>} />
        <Route path="/:shopCode/flyers" exact="true" element={<><Header {...props} /> <Flyers {...props} /><Footer {...props} /></>} />

        <Route path="/:shopCode/my-address" exact="true" element={<><Header {...props} /> <MyAddress {...props} /><Footer {...props} /></>} />
        <Route path="/:shopCode/edit-address" exact="true" element={<><Header {...props} /> <AddEditAddress {...props} /><Footer {...props} /></>} />

        <Route path="/:shopCode/my-order-history" exact="true" element={<><Header {...props} /> <MyOrderHistory {...props} /><Footer {...props} /></>} />
        <Route path="/:shopCode/order-details" exact="true" element={<><Header {...props} /> <OrderDetails {...props} /><Footer {...props} /></>} />

        <Route path="/:shopCode/product/:productID" exact="true" element={<> <Header {...props} /> <ItemDetail {...props} /><Footer {...props} /></>} />
        <Route path="/:shopCode/checkout" exact="true" element={<><Header {...props} /> <Checkout{...props} /><Footer {...props} /></>} />



        <Route path="/offers" exact="true" element={<Offers />} />
        <Route path="/listing" exact="true" element={<List />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/404" exact="true" element={<NotFound />} />
        <Route path="/extra" exact="true" element={<Extra />} />
        <Route path="/login" exact="true" element={<Login />} />
        <Route path="/register" exact="true" element={<Register />} />
        <Route path="/track-order" exact="true" element={<TrackOrder />} />
        <Route path="/invoice" exact="true" element={<Invoice />} />
        <Route path="/thanks" exact="true" element={<Thanks />} />
        <Route path="/detail" exact="true" element={<Detail />} />
        <Route exact="true" element={<NotFound />} />
      </Routes>

      {/* {
        (location.pathname !== '/login' && location.pathname !== '/register') ? <Footer {...props} /> : ''
      } */}

      <div className="App">
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
        {/* <header className="App-header">
          {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
          {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
          <Button onClick={() => setShow(true)}>Show Toast</Button>
        </header> */}


      </div>

    </>
  );
}

export default App;
