import actionTypes from "./cartUpdate.actionTypes";

const cartUpdateLoadStart = () => ({
	type: actionTypes.UPDATE_CART_LOAD_START,
});

const cartUpdateLoadSuccess = (shop) => ({
	type: actionTypes.UPDATE_CART_SUCCESS,
	payload: shop,
});

const cartUpdateLoadError = (errorMessage) => ({
	type: actionTypes.UPDATE_CART_ERROR,
	payload: errorMessage,
});

export default {
	cartUpdateLoadStart,
	cartUpdateLoadSuccess,
	cartUpdateLoadError,
};