import OrderService from "../../../../services/orders/orders.service"
import actions from "./orderDetails.actions";
import Cookies from 'universal-cookie';

export const loadOrderDetails = (orderSummaryID) => (dispatch) => {

	const cookies = new Cookies();

	dispatch(actions.orderDetailsLoadingStart());

	OrderService.getOrderDetails(
		cookies.get('sc_id'),
		cookies.get('sd_auth'),
		orderSummaryID)
		.then((response) => dispatch(actions.orderDetailsLoadingSuccess(response.data)))
		.catch((error) => dispatch(actions.orderDetailsLoadingError(error.message)));
};