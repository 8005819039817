import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Container, Button } from 'react-bootstrap';

import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { loadOrderDetails } from "../redux/reducers/report/orderDetails/orderDetails.thunks";

import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';
import CartDropdownItem from './cart/CartDropdownItem';
import { useLocation } from "react-router-dom";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

import FastfoodIcon from '@mui/icons-material/Fastfood';

import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const OrderDetails = (props) => {

	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		quantity: this.props.qty || 1,
	// 		show: this.props.show || true,
	// 		max: this.props.maxValue || 5,
	// 		min: this.props.minValue || 0
	// 	};
	// }

	const dispatch = useDispatch();

	const queryParams = new URLSearchParams(window.location.search);
	const id = queryParams.get('orderId');

	console.log(id)

	const { isOrderDetailsLoading, orderDetailsResponse, orderDetailsError } = useSelector((state) => state.orderDetailsReducer);

	useEffect(() => {
		// dispatch(loadOrderDetails(props.match.params.orderId));

		dispatch(loadOrderDetails(id));
	}, [])

	return (
		<>
			<section style={{ marginTop: 100 }}>
			</section>
			{
				orderDetailsResponse &&
				<section className="section bg-white osahan-track-order-page ">

					<Container className="pt-5 pb-5">
						<Row className="d-flex ">

							<Col md={6}>
								<div className="bg-white p-4 shadow-lg mb-2">
									<div className="mb-2">Order <h5> #{orderDetailsResponse.orderSummaryData.OrderNo}</h5>	</div>
									<h6 className="mb-1 mt-1">
										{orderDetailsResponse.orderSummaryData.shopResponseData.Name}
									</h6>
									<p className="text-gray mb-0"><Icofont icon="clock-time" /> {orderDetailsResponse.orderSummaryData.OrderDate} |
										{orderDetailsResponse.productList.length} Items | AED {orderDetailsResponse.orderSummaryData.NetAmount.toFixed(2).toString()}  </p>

									<Button className="font-weight-bold mt-2"
										onClick={() => { window.open('tel:' + orderDetailsResponse.orderSummaryData.shopResponseData.PrimaryMobile) }}>
										<Icofont icon="headphone-alt" /> Call Shop for Help
									</Button>
								</div>
								<div className="bg-white p-4 shadow-lg">
									<div className="osahan-track-order-detail po">
										<h5 className="mt-0 mb-3">Order Details</h5>
										<Row>
											<Col md={5}>
												<small>FROM</small>
												<h6 className="mb-1 mt-1">
													{orderDetailsResponse.orderSummaryData.shopResponseData.Name}
												</h6>
												<p className="text-gray mb-5">{orderDetailsResponse.orderSummaryData.shopResponseData.Address}</p>
												<small>DELIVER TO</small>
												<h6 className="mb-1 mt-1"><span className="text-black">
													{orderDetailsResponse.orderSummaryData.customerAddressResponseData.FirstName}
													{orderDetailsResponse.orderSummaryData.customerAddressResponseData.LastName}
												</span>
												</h6>
												<p className="text-gray mb-0">
													{orderDetailsResponse.orderSummaryData.customerAddressResponseData.Address1},
													{orderDetailsResponse.orderSummaryData.customerAddressResponseData.Address2},
													{orderDetailsResponse.orderSummaryData.customerAddressResponseData.Area},
													{orderDetailsResponse.orderSummaryData.customerAddressResponseData.City},
													{orderDetailsResponse.orderSummaryData.customerAddressResponseData.Country},
													{orderDetailsResponse.orderSummaryData.customerAddressResponseData.Address1}
												</p>
											</Col>
											<Col md={7}>
												<div className="mb-2"><small><Icofont icon="list" /> {orderDetailsResponse.productList.length} ITEMS</small></div>

												{orderDetailsResponse && orderDetailsResponse.productList.map(productRow => (
													<CartDropdownItem
														icoIcon='ui-press'
														imageUrl={productRow.ImageData[0].ImagePath}
														iconClass='text-danger food-item'
														title={productRow.ItemName}
														quantity={productRow.Quantity}
														price={'AED ' + productRow.SellingPrice.toFixed(2).toString()}
														totalPrice={'AED ' + (productRow.Quantity * productRow.SellingPrice).toFixed(2).toString()}
													/>
												))}
												<strong>
													<p className="mb-0 font-weight-bold text-black">TOTAL BILL


														<span className="float-right text-black" style={{ float: 'right' }}>
															AED 	{orderDetailsResponse.orderSummaryData.NetAmount.toFixed(2).toString()}</span></p>
												</strong>
												{/* <p className="mb-0 text-info"><small>Paid via Credit/Debit card
													<span className="float-right text-danger">$620 OFF</span></small>
												</p> */}
											</Col>
										</Row>
									</div>
								</div>
							</Col>

							<Col md={4} className="pb-4">

								<div className="bg-white p-4 shadow-lg mt-2" >
									{orderDetailsResponse && orderDetailsResponse.trackOrderList.map(orderStatusRow => (
										<>
											{orderStatusRow.IsCompleted ? <>
												<TimelineItem>
													<TimelineSeparator>
														<TimelineConnector />
														<TimelineDot color="success">
															<CheckIcon />
														</TimelineDot>
														<TimelineConnector />
													</TimelineSeparator>
													<TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
														<Typography component="span" sx={{ float: 'left' }}>
															{orderStatusRow.Heading}
														</Typography>
														<Typography sx={{ float: 'left', textAlign: 'left' }}>{orderStatusRow.Message}</Typography>
													</TimelineOppositeContent>
												</TimelineItem>
											</> : <>
												<TimelineItem>
													<TimelineSeparator>
														<TimelineConnector />
														<TimelineDot>
															<HourglassEmptyIcon />
														</TimelineDot>
														<TimelineConnector />
													</TimelineSeparator>
													<TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
														<Typography component="span" sx={{ float: 'left' }}>
															{orderStatusRow.Heading}
														</Typography>
														<Typography sx={{ float: 'left', textAlign: 'left' }}>{orderStatusRow.Message}</Typography>
													</TimelineOppositeContent>
												</TimelineItem>
											</>}
										</>
									))}
								</div>
								{/* <iframe title="Address" className="position-absolute" src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13696.650704896498!2d75.82434255!3d30.8821099!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1555184720550!5m2!1sen!2sin" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe> */}
								{/* <div className="osahan-point mx-auto"></div>
								<iframe className="position-absolute" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.5449420124833!2d55.335425515449444!3d25.252247635660733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d853638ba35%3A0xb9af9eaa04b8c04b!2sPromptTech%20Solutions!5e0!3m2!1sen!2sae!4v1652263689976!5m2!1sen!2sae" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

							</Col>
						</Row>
					</Container>
				</section>
			}
		</>
	);
}
export default OrderDetails;