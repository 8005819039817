import SignUpService from "../../../../services/auth/signUp.service";
import actions from "./signUp.actions";
import Cookies from 'universal-cookie';

export const signUpAsync = (messageID, verificationCode,
	userName,
	userMobile,
	userEmail,
	password) => (dispatch) => {

		console.log(messageID);
		console.log(verificationCode);
		console.log(userName);
		console.log(userMobile);
		console.log(userEmail);
		console.log(password);
	

		dispatch(actions.signUpLoadingStart());
		const cookies = new Cookies();

		SignUpService.signUp(messageID,
			verificationCode,
			userName,
			userMobile,
			userEmail,
			password,
			cookies.get('sc_token'),
			navigator.userAgent,
			navigator.userAgent,
			"1",
			"1.1",
			"")
			.then((response) => {
				console.log(response);
				dispatch(actions.signUpSuccess(response.data));
			})
			.catch((error) =>{
				console.log(error);
				dispatch(actions.signUpError(error.message));
			});
	};