import actionTypes from "./products.actionTypes";

const productsLoadStart = () => ({
	type: actionTypes.PRODUCT_LOAD_START,
});

const productsLoadSuccess = (products) => ({
	type: actionTypes.PRODUCT_LOAD_SUCCESS,
	payload: products,
});

const productsLoadError = (errorMessage) => ({
	type: actionTypes.PRODUCT_LOAD_ERROR,
	payload: errorMessage,
});

export default {
	productsLoadStart,
	productsLoadSuccess,
	productsLoadError,
};