import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types';

class AddressCard extends React.Component {

   render() {
      return (
         <Card className={"bg-white addresses-item mb-4 " + (this.props.boxClass)}>
            <div className="gold-members p-4">
               <div class="media">
                  <div className="media-body">
                     <h6 className="mb-1 text-secondary">{this.props.firstName} {this.props.lastName}</h6>
                     <div className="text-black">{this.props.Address1}</div>
                     <div className="text-black">{this.props.address2}</div>
                     <div className="text-black">{this.props.area}</div>
                     <div className="text-black">{this.props.city}</div>
                     <div className="text-black">{this.props.landMark}</div>
                     <div className="text-black">{this.props.country}</div>
                     <div className="text-black">{this.props.province}</div>
                     <p className="text-black">{this.props.remark}</p>

                     <div className="text-black">{this.props.emailID}</div>
                     <p className="text-black">{this.props.mobileNumber}</p>

                     <div className="mb-0 text-black font-weight-bold">
                        <Link className="text-primary" to="#" onClick={this.props.onEditClick}><Icofont icon="ui-edit" /> EDIT</Link>
                        <Link className="text-danger" to="#" onClick={this.props.onDeleteClick}><Icofont icon="ui-delete" /> DELETE</Link></div>
                  </div>
               </div>
            </div>
         </Card>
      );
   }
}

AddressCard.propTypes = {
   addressID: PropTypes.number.isRequired,
   firstName: PropTypes.string.isRequired,
   lastName: PropTypes.string.isRequired,
   Address1: PropTypes.string.isRequired,
   address2: PropTypes.string,
   area: PropTypes.string,
   city: PropTypes.string,
   landMark: PropTypes.string,
   country: PropTypes.string,
   emailID: PropTypes.string.isRequired,
   mobileNumber: PropTypes.string.isRequired,
   province: PropTypes.string,
   latitude: PropTypes.string.isRequired,
   longitude: PropTypes.string.isRequired,
   remark: PropTypes.string,
   isDefaultAddress: PropTypes.bool.isRequired,
   isHomeAddress: PropTypes.bool.isRequired,
   onEditClick: PropTypes.func.isRequired,
   onDeleteClick: PropTypes.func.isRequired
};

export default AddressCard;