import actionTypes from "./loadSingleProducts.actionTypes";

const singleProductsLoadStart = () => ({
	type: actionTypes.SINGLE_PRODUCT_LOAD_START,
});

const singleProductsLoadSuccess = (singleProduct) => ({
	type: actionTypes.SINGLE_PRODUCT_LOAD_SUCCESS,
	payload: singleProduct,
});

const singleProductsLoadError = (singleProductPricing) => ({
	type: actionTypes.SINGLE_PRODUCT_LOAD_ERROR,
	payload: singleProductPricing,
});

export default {
	singleProductsLoadStart,
	singleProductsLoadSuccess,
	singleProductsLoadError,
};