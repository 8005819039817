import axios from "axios";

const apiClient = () => {
	const { REACT_APP_API_URL, REACT_APP_API_REQUEST_HEADER_KEY } = process.env;

	let headers = {}
	headers.RequestKey = REACT_APP_API_REQUEST_HEADER_KEY
	const axiosInstance = axios.create({
		baseURL: REACT_APP_API_URL,
		headers,
		responseType: "json",
	});

	return axiosInstance;
};

export default apiClient;
