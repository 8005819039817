import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import AddAddressModal from '../components/modals/AddAddressModal';
import DeleteAddressModal from '../components/modals/DeleteAddressModal';
import AddressCard from '../components/common/AddressCard';
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { loadListAddress } from "../redux/reducers/address/listAddress/listAddressReducer.thunks";
import { Container, Button } from 'react-bootstrap';
import Icofont from 'react-icofont';
import { useParams } from "react-router-dom";

const MyAddress = (props) => {

	const [showAddressModal, setShowAddressValue] = React.useState(false);
	const [showDeleteModal, setDeleteModalValue] = React.useState(false);
	const { shopCode } = useParams();

	const dispatch = useDispatch();
	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
	const { isAddressListLoading, listAddressResponse, listAddressError } = useSelector((state) => state.listAddressReducer);

	const handleAddAddressModal = (newValue) => {
		setShowAddressValue(newValue);
	};

	const handleDeleteAddressModal = (newValue) => {
		setDeleteModalValue(newValue);
	};

	useEffect(() => {

		dispatch(loadListAddress());

	}, [shop])

	function show(event) {
		event.preventDefault();
		this.setState({ showAddressModal: true })
	}

	return (
		<>
			<section style={{ marginTop: 110 }}>
			</section>

			<section className="section pb-5 bg-white">

				{/* <AddAddressModal show={showAddressModal} />
				<DeleteAddressModal show={showDeleteModal} /> */}

				<AddAddressModal show={showAddressModal} />
				<DeleteAddressModal show={showDeleteModal} />

				<div className='p-4 bg-white shadow-sm'>
					<Row>
						<Col md={12}>
							<h4 className="font-weight-bold mt-0 mb-3">Manage Addresses</h4>
							<Link to={"/" + shopCode + "/edit-address"}
								className="text-black"> <Button className="font-weight-bold mt-2">
									<Icofont icon="headphone-alt" /> Add New Address
								</Button></Link>

						</Col>

						{isAddressListLoading ? <ShimmerSimpleGallery card imageHeight={60} caption /> :
							<>
								{listAddressResponse && listAddressResponse.Data.map(addressRow => (
									<Col md={3}>
										<AddressCard
											boxClass="shadow-sm"
											title={addressRow.FirstName}
											icoIcon='location-pin'
											iconclassName='icofont-3x'
											address={addressRow.Address1}
											area={addressRow.Area}
											addressID={addressRow.AddressID}
											firstName={addressRow.FirstName}
											lastName={addressRow.LastName}
											Address1={addressRow.Address1}
											address2={addressRow.Address2}
											city={addressRow.City}
											landMark={addressRow.Landmark}
											country={addressRow.Country}
											emailID={addressRow.EmailAddress}
											mobileNumber={addressRow.MobileNumber}
											province={addressRow.Province}
											latitude={addressRow.Latitude}
											longitude={addressRow.Longitude}
											remark={addressRow.Remark}
											isDefaultAddress={addressRow.IsDefault}
											isHomeAddress={addressRow.IsHomeAddress}
											onEditClick={() => show}
											onDeleteClick={() => this.setState({ showDeleteModal: true })}
										/>
									</Col>
								))}
							</>
						}
					</Row>
				</div>

			</section>


		</>
	);
}

export default MyAddress;