import apiClient from "../../helpers/apiClient";

class ListAddress {
	getCustomerAddress = (userID = "", authenticationKey = "") => apiClient().post('/GetCustomerAddresses',
		{
			UserID: userID,
			AuthKey: authenticationKey,
		}
	);
}
export default new ListAddress();