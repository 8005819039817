import actionTypes from "./listAddressReducer.actionTypes";

const listAddressLoadStart = () => ({
	type: actionTypes.LIST_ADDRESS_LOADING_START,
});

const listAddressLoadSuccess = (listAddressResponse) => ({
	type: actionTypes.LIST_ADDRESS_SUCCESS,
	payload: listAddressResponse,
});

const listAddressLoadError = (listAddressError) => ({
	type: actionTypes.LIST_ADDRESS_ERROR,
	payload: listAddressError,
});

export default {
	listAddressLoadStart,
	listAddressLoadSuccess,
	listAddressLoadError,
};