import apiClient from "../../helpers/apiClient";

class OrderService {

	//Show list of the orders
	getOrderList = (userID = "", authenticationKey = "", shopID = 0) => apiClient().post('/GetOrderSummaryList',
		{
			UserID: userID,
			AuthKey: authenticationKey,
			ShopID: shopID
		}
	);

	//Show details of the order
	getOrderDetails = (userID = "", authenticationKey = "", orderSummaryID = 0) => apiClient().post('/GetOrderSummaryDetails',
		{
			UserID: userID,
			AuthKey: authenticationKey,
			OrderID: orderSummaryID
		}

	);
}
export default new OrderService();